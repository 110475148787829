/* eslint-disable */

.featherlight-hide {
  display: none;
}

.featherlight .featherlight-hide {
  display: block;
}

.featherlight {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background: #333;
  background: rgba(0,0,0,0);
}

.featherlight:last-of-type {
  background: rgba(255,255,255,0.7);
}

.featherlight::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.featherlight .featherlight-content {
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: auto;
  padding: 60px;
  background-color: $kf-highlight;
  max-height: 95%;
  cursor: auto;
  white-space: normal;
  width: 750px;
  max-width: 90%;


  h2, h3, h4, h5 {
    color: $kf-contrast;
  }

  h3 {
    font-family: $font-light;
    margin-bottom: 20px;
    font-weight: 200;
  }

  p {
    color: $kf-contrast;
    font-size: 1.125em;
    font-weight: 200;
  }
}

.featherlight .featherlight-inner {
  display: block;
}

.featherlight link.featherlight-inner,.featherlight script.featherlight-inner,.featherlight style.featherlight-inner {
  display: none;
}

.featherlight .featherlight-close-icon {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  line-height: 25px;
  width: 25px;
  cursor: pointer;
  text-align: center;
  font-family: Arial,sans-serif;
  background: #fff;
  background: rgba(255,255,255,0.3);
  color: #000;
  border: 0;
  padding: 0;
}

.featherlight .featherlight-close-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.featherlight .featherlight-image {
  width: 100%;
}

.featherlight-iframe .featherlight-content {
  border-bottom: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.featherlight iframe {
  border: 0;
}

.featherlight * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 1024px) {
  .featherlight .featherlight-content
  {
    margin-left: 0;
    margin-right: 0;
    max-height: 98%;
    padding: 10px 10px 0;
    border-bottom: 10px solid transparent;
  }
}

@media print {
  html.with-featherlight > * > :not(.featherlight)
  {
    display: none;
  }
}
