.login-navi {
  display: block;
  padding-top: 2em;
  padding-bottom: 2em;

  h4 {
    margin-top: 1em;
    font-size: 2em;
    line-height: 100%;
    font-family: $font-headline;
    margin-bottom: 0;
    max-width: 450px;
  }

  ul {
    padding: 0;
    margin: 2em 0 3em 0;
    padding-left: 2em;

    li {
      padding: 0;
      margin: 0;
      font-size: 1.125em;
      font-family: $font-headline;
      font-weight: 300;
      margin-bottom: 5px;
      padding-left: 0.5em;
      position: relative;
    }
  }

  p {
    max-width: 450px;
    font-size: 1.125em;
  }

  a.inline-link {
    text-decoration: underline;
    font-size: 0.875em;
    font-weight: 300;
    margin-top: 0.5em;
  }

  input[type="text"] {
    margin-bottom: 2em;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    font-size: 0.75em !important;
  }

  .login-additonal-text {
    font-size: 0.875em;
  }

  .img-col {
    display: flex;
    justify-content: flex-end;

    .img-col-image {
      width: 100%;
      align-self: flex-start;
    }
  }

  .login-col {
    border-left: 1px solid $grey_ddd;
    padding-left: 2em;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: 800px) {
      border-left: 1px solid $grey_ddd;
    }

    p.failed {
      color: $kf-error;
      font-size: 0.875em;
    }

    #user_pass {
      margin-bottom: 10px !important;
    }

    #user_login {
      margin-bottom: 10px !important;
      margin-top: 10px !important;
    }

    .inline-link {
      margin-bottom: 20px;
    }

    form {
      flex-direction: column;
      display: flex;

      p {
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 0.875em;

        a {
          text-decoration: underline;
        }
      }

      .kf-btn-black {
        margin-top: 0;
      }
    }

    .client-custom-login{
      text-align: right;
      font-size: 0.875em;
      margin-top: 15px;
      text-decoration: underline;
      font-weight: 300;

      a::after{
        content: ' →';
      }
    }
  }

  .teaser-col {
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

}

.search-navi {
  .kf-slogan {
    display: none;
  }

  .kf-bar-enclosure {
    padding-top: 0 !important;
  }
}
