/** Search form */
.search-form {
  @extend .form-inline;
}

.search-form label {
  @extend .form-group;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.bih-field {
  input {
    vertical-align: top !important;
  }

  label {
    margin-top: -10px !important;
  }

  input:checked ~ label > a > img.form-band-preview, input:checked ~ a, input:checked ~ label > a {
    display: inline-block !important;
  }

  u.bih-link {
    text-decoration: underline;
    font-size: 13px;
    color: $article_caption;
  }
}

.dropdown-toggle {
  background-color: $kf-contrast;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.65em;

  .bs-caret {
    .caret  {
      height: 0.8em;
      width: 0.8em;
      background-image: url('../images/icon_carousel_arrow_left.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(-90deg);
      margin-top: -5px !important;
      margin-right: 0;
      top: 48%;
    }
  }

  &[aria-expanded="true"] {
    .caret {
      transform: rotate(-270deg);
      top: 45% !important;
    }
  }

  + .dropdown-menu,
  > .dropdown-menu  {
    padding: 10px;
    border-radius: 15px;
    width: auto;

    .hidden {
      display: none;
    }

    .no-results {
      display: none;
    }

    .bs-searchbox {
      padding: 0;
      width: 180px;
      position: relative;

      .form-control {
        background-color: $grey_f5f5f5;
        color: $grey_9B9B9B;
        height: 30px !important;
        border-radius: 8px;
        border: 0;
        padding-right: 30px !important;
        padding-left: 8px !important;

        &:focus {
          outline: none !important;
        }
      }

      &::after {
        position: absolute;
        top: 6px;
        right: 8px;
        height: 16px;
        width: 16px;
        content: '';
        opacity: 0.5;
        background-image: url('../images/search.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .dropdown-menu [role="option"] {
      font-weight: 300;
      line-height: 1.2em;
      padding: 3px 0;
      display: block;
    }

    a:focus {
      outline: none;

      span {
        outline: none;
      }
    }
  }

  &::after {
    display: none;
  }
}

input:focus {
  outline: none !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  appearance: none;
  width: 100% !important;
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0 !important;
  line-height: 40px !important;
  height: 40px !important;
  font-size: 0.875em !important;
  font-family: $font-system !important;
  font-weight: 300 !important;
  border-bottom: 1px solid $grey_9B9B9B;
  margin-bottom: 20px !important;

  &:focus {
    border-bottom: 1px solid #000;
  }
}

.input-hidden {
  display: none;
}

.gform_wrapper {
  margin: 0 auto;
  max-width: 90%;

  .validation_error {
    margin: 0 0 20px 0 !important;
    padding: 0 0 !important;
    font-size: 0.875em !important;
    line-height: 1.4em !important;
    border-bottom: 1px solid #790000 !important;
    font-weight: 400 !important;
    text-align: left !important;
    border: 0 !important;
  }

  li {
    &.gfield {
      width: 100% !important;
      padding-right: 0 !important;
      margin-top: 0 !important;
    }

    &.gfield_error {
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      max-width: 100% !important;
      width: 100% !important;

      input {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom: 1px solid #790000 !important;
      }

      .gfield_description.validation_message {
        padding: 10px 10px 5px 10px !important;
        margin-bottom: 20px !important;
        font-weight: 400 !important;
        line-height: 1.4em !important;
        font-size: 0.875em !important;
        background-color: rgba(255, 223, 224, 0.25) !important;
      }
    }
  }

  .bih-field {
    div > ul > li {
      padding-top: 10px !important;
    }
  }

  .gform_fields {
    justify-content: space-between !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column !important;
    }

    #field_13_55 {
      display: none;
    }

    .gfield {
      margin-bottom: 20px !important;
    }

    .gfield_contains_required .ginput_container:not(.ginput_container_radio)::before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 1px;
      z-index: 99;
      color: $kf-highlight;
    }

    .column-left {
      flex: 0 1 auto;
      width: calc(50% - 30px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      img.form-band-preview, a {
        display: none;
      }

      img.form-band-preview {
        max-width: 150px;
        margin-right: 5px;
      }

      .half.gfield_visibility_visible {
        max-width: calc(50% - 8px) !important;
        width: calc(50% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 50%;
      }

      .half.gfield_visibility_visible + .half.gfield_visibility_visible {
        margin-left: 16px !important;
      }

      .onethird.gfield_visibility_visible {
        max-width: calc(34% - 8px) !important;
        width: calc(34% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 34%;
      }

      .twothird.gfield_visibility_visible {
        max-width: calc(66% - 8px) !important;
        width: calc(66% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 66%;
      }

      .twothird.gfield_visibility_visible + .onethird {
        margin-left: 16px !important;
      }

      .onethird.gfield_visibility_visible + .twothird {
        margin-left: 16px !important;
      }
    }

    .column-right {
      flex: 0 1 auto;
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
    }

    .gfield_checkbox {
      input {
        margin-top: -5px !important;
      }

      label {
        font-size: 1.125em !important;
        font-weight: 300 !important;
        margin-left: 15px;
      }
    }

    .gfield_select {
      width: 100% !important;
      max-width: 100%;

      .gfield_select {
        display: none !important;
      }

      .dropdown-toggle {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-left: 0 !important;
        font-weight: 300 !important;
        margin-top: 2px !important;
      }
    }

    .gfield.column-1 {
      break-inside: avoid-column;
    }

    .gfield.column-2 {
      break-inside: avoid-column;
    }

    .gfield_html {
      margin-bottom: 0 !important;
      font-family: $font-headline;
      font-size: 1em;

      p {
        font-family: $font-system;
        font-size: 1.25em;
        margin-bottom: 1em;
      }

      h3 {
        font-size: 2em;
        margin-bottom: 15px;
      }
    }

    .gfield_radio {
      input[type="radio"] {
        margin-top: -6px !important;
      }

      label {
        font-size: 1.125em;
        font-weight: 300 !important;
        margin-left: 10px;
      }

      input[type="text"] {
        width: calc(100% - 25px) !important;
        font-size: 1.125em;
      }
    }
  }

  .multicol .gform_fields {
    flex-direction: column !important;
  }
}

label.gfield_label {
  display: none !important;
}

.ginput_container {
  margin-top: 0 !important;
  position: relative;
}

.gform_footer {
  padding: 0 !important;
  margin: 0 !important;
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_footer input[type="submit"],
.gform_wrapper .gform_page_footer input[type="submit"] {
  font-size: 0.8em !important;
  margin-top: 15px;
}


.gform_heading {
  display: none;
}

.code-valid {
  &::after {
    color: $kf-highlight;
    content: '\2713 10,00 EUR geschenkt';
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    font-weight: 300;
    font-size: 0.875em;
  }
}

.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  max-width: 100% !important;

  &.half {
    max-width: calc(50% - 8px) !important;
  }

  &.onethird {
    max-width: calc(34% - 8px) !important;
  }

  &.twothird {
    max-width: calc(66% - 8px) !important;
  }
}

#gform_wrapper_30 {
  .gform_body {
    #input_30_5 {
      float: right !important;
      display: flex;
      position: absolute;
      right: 10%;
      background-color: white !important;

      @media (max-width: 480px) {
        margin-bottom: 30px !important;
        position: initial !important;
      }
    }

    .grecaptcha-badge {
      width: 300px !important;
    }

    iframe {
      width: 300px !important;
    }

  }
}

#gform_wrapper_48 {
  #field_48_59 {
    display: none;

    .grecaptcha-badge{
      width: 300px !important;
    }

    iframe{
      width: 100% !important;
    }
  }

  @media (min-width: 480px) {
    #field_48_59 {
      width: 42% !important;
      float: right !important;
      position: absolute;
      right: 13%;
      background-color: white !important;
      margin-top: 30px !important;
    }

    .gform_footer {
      margin-top: 35px !important;
    }
  }

  @media (max-width: 480px) {
    #field_48_59 {
      justify-content: center;
      margin-top: 20px !important;
    }
  }
}

.abo-kuendigung_wrapper.gform_wrapper .gform_fields .gfield_radio label,
.abo-kuendigung_wrapper.gform_wrapper .gform_fields .gfield_html p {
  line-height: 1.4em;
  font-size: 16px;
}
