body.single-3d-flip-book {
  #header, hr, #footer {
    display: none;
  }
}

.fancybox-container .fancybox-content {
  width  : 950px;
  height : 700px;
  max-width  : 80%;
  max-height : 80%;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .fancybox-container .fancybox-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .fancybox-slide {
    padding: 45px 10px 10px 10px;
  }
}

nav.fnavbar {
  display: none !important;
}
