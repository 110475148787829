.expand-list {
  .hidden {
    display: none;
  }

  .show {
    display: block;
  }
}

.kf-stage {
  margin-top: 0;
  height: auto !important;
  min-height: auto !important;
}

.main-navi {
  display: flex !important;
  flex-direction: row-reverse !important;
}

// .site-footer {
//     .container {
//       padding: 0;
//     }

//     .kf-input {
//       display: inline;
//     }

//     input {
//       margin-bottom: 4em;
//     }

//     form {
//       padding: 0;
//     }

//     li {
//       display: inline-block;
//     }
//   }

.capitalize {
  text-transform: uppercase;
}

a.link_18px_red:link,
a.link_18px_red:visited {
  font-size: (13 / 13) * 1em;
  color: $kf-highlight;
  text-decoration: none;
}

//hover/active state not defined yet
a.link_18px_red:active,
a.link_18px_red:hover {
  font-size: (13 / 13) * 1em;
  color: $kf-highlight;
  text-decoration: underline;
}

.newsletter_modal {
  position: absolute;
  width: 370px;
  background-color: $kf-highlight;
  text-align: center;
  right: 0;
  top: 1300px;
  padding: 3em 4em;
  z-index: 10;

  span {
    display: block;
    color: #fff;
    margin-bottom: 1em;
  }

  .nl-modal-title {
    font-size: (30 / 13) * 1em;
  }

  .nl-modal-text {
    font-family: $font-headline;
    font-size: (18 / 13) * 1em;
  }
}

.probelesen {
  background-color: $kf-alternate;
  padding: 4em 0;

  .probe_title {
    line-height: 3em;

    .probe_title_kunstforum {
      display: inline-block;
      font-weight: 300;
      font-size: 3.125em;
      margin-right: 0.2em;
    }

    .probe_title_probelesen {
      font-weight: 300;
      font-size: 3.125em;
    }
  }

  p {
    font-family: $font-headline;
    font-size: 1.25em;
    line-height: 1.4em;
    margin-bottom: 1.5em;
    margin-top: 0.5em;

    span {
      font-family: $font-system;
    }
  }

  ul.vorteilsabo_ul {
    margin-bottom: 2.4em;
    padding-left: 1.5em;
    margin-top: 15px;

    li {
      font-family: $font-headline;
      font-size: 1.25em;
      margin-bottom: 5px;
    }
  }
}

.probelesen.vorteilsabo_block {
  .probe_title {
    span.probe_title_kunstforum {
      line-height: 1.1em;
      font-size: 3.125em
    }

    span.probe_title_probelesen {
      font-family: $font-headline;
      line-height: 1.1em;
    }
  }
}

.newsletter_modal_wrapper {
  position: relative;
}

.nl_modal_closex {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
}

span.uppercase {
  text-transform: uppercase;
}

.wide_newsletter_element {
  background-color: $kf-highlight;
  text-align: center;
  padding: 4em;
  margin-top: 5em;

  @media (max-width: 800px) {
    padding: 60px 30px;
  }

  .nl_element_title {
    font-size: 2em;
    font-weight: 300;
    padding-bottom: 1em;
    color: #fff;
    text-transform: uppercase;
  }

  .nl_element_text {
    font-family: $font-headline;
    font-size: 1.25em;
    line-height: 1.4em;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    color: #fff;
  }

  .gform_wrapper {
    max-width: 100% !important;

    form {
      display: flex;
      flex-direction: column !important;
      margin: 0 auto;
      margin-top: 20px;

      @media (max-width: 480px){
        flex-direction: column !important;
      }

      .gfield {
        margin-bottom: 0 !important;
      }

      .validation_message {
        margin-bottom: 0 !important;
      }

      .gform_footer {
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;

        .gform_button {
          margin-left: 20px;
        }
      }
    }

    .gform_confirmation_message {
      color: $kf-contrast;
      font-size: 1.125em;
    }

    input[type="submit"] {
      margin: 0;
      margin-top: 0 !important;
      margin-left: 20px;
      color: $kf-highlight;
      background-color: $kf-contrast;
      border-color: $kf-contrast;

      @media (max-width: 480px) {
        margin-left: 0;
      }
    }

    &.gform_validation_error {
      form {
        display: flex;
        flex-direction: column;
      }

      .validation_error {
        margin-bottom: 10px !important;
      }

      input[type="submit"] {
        margin-left: 0;
      }
    }
  }

  ul {
    max-width: 600px;

    &.vorteile {
      padding: 0;
      margin: 10px 0 20px 0;

      li {
        color: $kf-alternate;
        padding: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 300;
        margin-bottom: 5px;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
        text-align: left;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  p {
    margin-top: 20px;
    color: #fff;
    font-size: 0.875em;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;

    a {
      text-decoration: underline;
    }
  }

  input {
    height: 40px;
    background-color: $kf-highlight;
    border-bottom: 1px solid $kf-contrast !important;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    color: $kf-contrast;
    margin-bottom: 2em;
    width: 20em;
    margin-right: 1em;

    &::-webkit-input-placeholder {
      color: #fff;
    }
  }

  a.btn-kf-footer_outline:link,
  a.btn-kf-footer_outline:visited {
    color: #fff;
  }

  .btn-kf-footer_outline {
    background-color: $kf-highlight;
    border-radius: 1.2em;
    border-color: #fff;
    font-size: 1em;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8em 1.5em;
  }
}

.add_8em_below {
  margin-bottom: 8em;
}

.add_4em_side_padd {
  padding: 0 4em;
}

.add_4em_padd {
  padding: 4em;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;

  &.align-bottom {
    align-items: end;
  }
}
