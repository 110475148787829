.newsletter {
  .maincontent {
    position: relative;
  }

  .content-nl-abonnieren {
    padding-top: 2em;
    padding-bottom: 8em;
  }

  .newsletter-legals {
    margin-top: 60px;

    .legal-col {
      margin-bottom: 20px;
    }
  }

  .text-col {
    padding-right: 120px;

    ul {
      padding: 0;
      margin: 30px 0 60px 0;

      li {
        padding: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 300;
        margin-bottom: 15px;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    p {
      a {
        text-decoration: underline;
      }
    }
  }

  .form-col {
    margin-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 60px;

    h3 {
      margin-top: 35px;
      margin-bottom: 20px;
    }

    a.kf-btn {
      margin-top: 10px;
    }

    p {
      a {
        text-decoration: underline;
      }
    }
  }

  .collapse.show {
    display: flex !important;
  }

  #collapse-btn{
    cursor: pointer;
    font-weight: 300 !important;
  }

  .fa {
    font-size: 25px !important;
    margin-left: 5px;
    vertical-align: middle !important;
  }
}

.newsletter-widget {
  .gform_confirmation_message {
    color: $kf-contrast;
    font-weight: 300;
  }
}
