.single-artikel {
  .person {
    margin-bottom: 1em;
  }

  &.is-guest {
    .content-slider {
      display: none !important;
    }

    .lazyload-img {
      display: none !important;
    }
  }

  .article-navigation {
    position: fixed;
    z-index: 999;
    top: 50%;
    width: 32px;
    text-align: center;
    transform: translateY(-50%);

    @media screen and (max-width: 480px) {
      display: none;
    }

    @media screen and (min-width: 640px) {
      width: 48px;

      a {
        width: 48px;
      }
    }

    &.article-navigation-prev {
      left: 10px;
    }

    &.article-navigation-next {
      right: 10px;
    }

    a {
      position: static;
      transform: none;

      &::before {
        margin: 0 auto;
      }
    }

    .navigation-info {
      display: block;
      margin-top: 10px;
      font-size: 10px;
      line-height: 12px;
      color: grey;
      opacity: 0.7;
      hyphens: auto;
    }
  }
}

.article_content_listing {
  padding: 0;

  div {
    padding-bottom: 4em;

    span {
      display: block;
      font-size: 1.56em;
      font-weight: 300;
      color: #000;
    }
  }
}

.article-relations {
  .article_content_listing {
    padding-top: 0;

    &.readmore {
      height: 200px;
      overflow: hidden;
    }
  }

  &#myTabContent .readmore > .container {
    height: -webkit-max-content;
    height: max-content;
  }

  .blur {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 80px;
    width: 100%;
    height: 150px;
    text-align: center;
    margin-top: 0;
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.kf-mini-images-item {
  margin-top: 2.5em;
  margin-bottom: 2em;
}

.research-largeexhibitions-result{
  a.largeexhibition {
    display: block;
    font-size: 1.56em;
    font-weight: 300;
    color: #000;
    text-align: left;
    padding-bottom: 0.25em;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  a.link {
    padding-bottom: 3em;
  }
}

.research-institutions-result {
  a.institution {
    display: block;
    font-size: 1.56em;
    font-weight: 300;
    color: #000;
    text-align: left;
    padding-bottom: 0.25em;

    &:hover,
    &:active {
      color: $kf-highlight;
      text-decoration: none;
    }
  }

  a.link {
    padding-bottom: 3em;
  }
}


.person_search_results {
  .index {
    color: $kf-highlight;
    font-family: $font-system;
    font-size: 1.125em;
    padding-top: 0;
    padding-bottom: 1em;
    margin: 0;
    font-weight: normal;
    text-align: left;

    &:first-child {
      padding-top: 0;
    }
  }

  .section-title {
    margin-top: 0;
  }

  .person {
    display: block;
    font-size: 1.56em;
    line-height: 1.25em;
    font-weight: 200;
    color: #000;
    margin-bottom: 20px;

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &.hidden {
      display: none;
    }

    + .index {
      padding-top: 1em;
    }
  }

  .more {
    display: block;
    font-size: 1.56em;
    font-weight: 300;
    color: #000;
    margin-bottom: 40px;

    &.hidden {
      display: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

}

.kf-search-research-filter {
  padding: 2em 0 3em 0;
  flex-wrap: wrap;
  flex-direction: row !important;

  @media (max-width: 768px){
    flex-direction: row !important;
    flex-wrap: wrap;

    .filter-holder {
      .bootstrap-select {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }

  .select-wrapper {
    margin-right: 1.5em;
    width: 100px;
  }

  .bootstrap-select {
    margin-right: 15px;
    width: auto !important;

    &:last-child {
      margin-right: 0;
    }
  }

  .label {
    color: $grey_9B9B9B;
    font-size: 1em;
    font-family: $font-headline;
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    margin-left: 1.5em;
    height: 40px;
    line-height: 40px;

    @media (max-width: 768px) {
      vertical-align: super;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
    }
  }

  .dropdown-toggle {
    border: 1px solid $grey_ddd;
    border-radius: 15px;

    &.bs-placeholder {
      color: #000;
    }
  }

  .kf-text-search {
    flex: 0 1 auto;
    position: relative;
    max-width: 50%;
    width: 300px;

    @media (max-width: 768px) {
      width: 100%;
      flex: 0 1 100%;
      max-width: 100%;
    }

    input[type='text'] {
      border-radius: 15px;
      height: 40px !important;
      padding: 0.375rem 0.75rem 0 !important;
      padding-right: 3rem !important;
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
      color: #9b9b9b;
      font-size: 1em !important;
      width: 100% !important;
    }

    input[type='image'] {
      position: absolute;
      top: 8px;
      right: 0.75em;
      margin: auto;
      height: 21px;
      width: 21px;
    }
  }
}

.kf-article-stage {
  background-color: $kf-contrast;
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0;

  &.alternate {
    background-color: $kf-alternate;
  }

  .kf-stage-extras {
    width: 100%;
    padding: 1em;
    color: white;

    .kf-stage-search {
      position: relative;
      width: 40%;

      input[type='text'] {
        border-radius: 14px;
        width: 100%;
        padding: 0.7em 3em 0.7em 1.5em;
        border: 0;
      }

      input[type='image'] {
        position: absolute;
        top: 0.7em;
        right: 0.75em;
      }
    }

    .kf-stage-claim {
      font-size: 2em;
      width: 40%;
      display: block;
    }

    .kf-stage-annotation {
      width: 40%;
      display: block;
      font-size: 0.8em;
    }
  }

  .kf-bar-enclosure {
    padding: 70px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 360px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .kf-bar {
      width: 100%;
      height: 0;
      padding-bottom: 12.5%;
      background-color: $kf-highlight;
      position: relative;

      img.logo {
        height: 100%;
        width: auto;
        padding: 0.75rem;
        position: absolute;
        right: 0;
      }

      @media (max-width: 768px) {
        padding-bottom: 90px !important;
      }
    }

    .kf-bar:hover {
      cursor: pointer;
    }

    &::after {
      content: '';
      display: inline-block;
      clear: both;
    }

    .kf-slogan {
      color: $kf-highlight;
      font-size: 1.25em;
      margin-top: 1em;
      max-width: 50%;
      flex: 0 1 50%;
      text-align: right;

      @media (max-width: 768px) {
        font-size: 1em;
        max-width: 60%;
      }

      @media (max-width: 480px) {
        max-width: 50%;
        text-align: right;
      }

      @media (max-width: 360px) {
        flex: 0 1 100%;
        max-width: 100%;
        order: 2;
      }

      &.breadcrumb {
        text-align: left;

        a.bc-link {
          text-decoration: underline;
        }

        @media (max-width: 480px) {
          text-align: left !important;
        }

        @media (max-width: 360px) {
          flex: 0 1 100%;
          max-width: 100%;
          order: 3;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }

      a {
        color: $kf-highlight;
      }
    }
  }
}

.article-row {
  max-width: 1095px;
  margin-left: auto;
  margin-right: auto;
}

.article_h1 {
  font-family: $font-headline;
  font-size: 4em;
  line-height: 1.05em;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.article_subtitle {
  font-size: 2em;
  line-height: 1.1em;
  text-align: center;
  padding-bottom: 0.5em;
}

.article_meta {
  font-size: 0.875em;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 2em;
}

.article_rubriken {
  font-size: 0.875em;
  text-align: center;
  padding-bottom: 60px;
}

.article_social {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding-bottom: 60px;
  justify-content: center;

  @media (max-width: 480px) {
    width: 200px;
    margin: 0 auto;
  }

  .social-link {
    margin-right: 1.5em;
  }
}

.article_text_small {
  font-size: (20 / 13) * 1em;
  line-height: (28 / 13) * 1em;
  padding-bottom: 2em;
}

.article_text_small_light {
  font-size: (20 / 13) * 1em;
  line-height: (28 / 13) * 1em;
  padding-bottom: 1em;
}

.article_text_small_alt {
  font-family: $font-headline;
  font-size: (20 / 13) * 1em;
  line-height: (28 / 13) * 1em;
  padding-bottom: 2em;
  text-indent: 4em;
}

.interview_qa_padding {
  font-weight: 200;
  padding-bottom: 0;
}

.article_img_small {
  text-align: center;
}

.article_img_small_caption {
  font-size: (12/13) * 1em;
  color: $article_caption;
  text-align: left;
  margin-top: 1em;
  padding-bottom: 2em;

  p {
    margin-bottom: 0.2em;
  }
}

.icon_img_enlarge {
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url('../images/icon_image_enlarge.svg') no-repeat center right;
  background-size: 100% 100%;
  margin-top: 1em;
}

.icon_nl_close {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../images/icon_close_x.svg') no-repeat center right;
  background-size: 100% 100%;
}

.article_quote {
  font-family: $font-headline;
  font-size: (48 / 13) * 1em;
  line-height: 1.5em;
  letter-spacing: -0.5px;
  padding-bottom: 2em;
  padding-top: 1em;
  text-align: center;
}

.article_author_footer {
  border: 1px solid #000;
  padding: 4em;
  margin: 4em 0;

  .author_name {
    font-size: (18 / 13) * 1em;
  }
}

.col12_divider {
  border-style: solid;
  border-color: $grey_ddd;
  border-width: 0 0 1px 0;
  padding-bottom: 4em;
  margin-bottom: 4em;
}

.kf-article-misc-articles {
  padding-bottom: 4em;

  img {
    width: 100%;
  }

  h1 {
    font-size: 2.3em;
    padding: 0.6em 0 0.3em 0;
  }

  h3 {
    color: black;
    text-transform: none;
  }

  .row {
    padding-bottom: 5em;
  }

  .article_side_info {
    border: 1px solid #000;
    padding: 0.5em;
    margin-top: (64/13) * 1em;
    text-align: center;

    img {
      display: inline-block;
      padding: 2em 0;
    }

    a:link,
    a:visited,
    a:active,
    a:hover {
      display: inline-block;
      font-size: (16/13) * 1em;
      text-decoration: underline;
      padding-bottom: 1em;
    }
  }

  .kf-single-item {
    h1 {
      font-size: 4.9em;
      padding: 0;
    }

    h3 {
      font-size: 2.3em;
      color: black;
      text-transform: none;
    }

    img {
      width: 100%;
    }
  }

  .kf-mini-item {
    img {
      width: 100%;
    }

    h1 {
      font-size: (32/13) * 1em;
      padding: 0;
    }

    h3 {
      font-size: (18/13) * 1em;
      color: black;
      text-transform: none;
      padding: 0;
    }
  }
}

.kfi-front-misc-articles {
  padding-top: 4em;
}

.linked-author-holder {
  padding: 1em 0 0;
  max-width: 80%;
  width: calc(650px + 8em);
  margin: 0 auto 3em auto;

  @media (min-width: 1200px) {
    max-width: 750px;
    width: 750px;
  }

  @media (min-width: 1440px) {
    max-width: 850px;
    width: 850px;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  @media (max-width: 1100px) and (min-width: 1000px) {
    max-width: 90%;
  }


  @media (max-width: 480px) {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .hl-text {
    color: $kf-highlight;
    font-size: 1.125em;
    text-align: center;
    margin: 0 0 0.25em 0;
  }

  .highlight-box {
    border: 1px solid #000;
    padding: 3em;
    margin-bottom: 2em;

    @media (max-width: 480px) {
      padding: 25px 15px;
    }

    .txt-col {
      padding-left: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 480px){
        margin-top: 30px;
      }
    }

    h4 {
      font-size: 1.125em;
      font-weight: 300;
      line-height: 1.1em;
      margin-bottom: 0;
      text-align: left;
      font-family: $font-system;
    }

    h5 {
      font-size: 1.125em;
      margin-bottom: 0.5em;
    }

    a.inline-link {
      color: $kf-highlight;
      text-decoration: underline;
      font-size: 0.875em;
    }

    img {
      width: 80%;
    }

    .highlight-meta {
      font-size: 0.75em;

      > span {
        font-size: 1em;
      }
    }
  }
}
