.tab-row {
  padding-bottom: 0 !important;
}

.tab-pane {
  padding: 5em 0;

  &#personen {
    a.person {
      margin-bottom: 20px;
    }
  }
}

.tab-content {
  position: relative;

  &::after {
    background-image: url(../images/loading-puff.svg) !important;
    background-size: 80px !important;
    background-repeat: no-repeat !important;
    background-position: center 100px !important;
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
    z-index: 999;
  }

  &.loading {
    &::after {
      content: '';
      display: block;
    }
  }
}

.content-tabs {
  border-style: solid;
  border-color: $grey_ddd;
  border-width: 0 0 1px 0;

  .nav-tabs {
    list-style-type: none;
    padding: 0;
    display: inline-block;
    margin-bottom: 0;
    border: none;
    margin-top: 40px;
  }

  li.nav-item {
    float: left;
    padding: 0;
    margin: 0 22px;

    @media (max-width: 480px){
      margin: 0 10px;
    }
  }

  .nav-link {
    font-size: 1.125em;
    font-weight: 500;
    border-style: solid;
    border-color: transparent;
    border-width: 0 0 2px 0;
    padding-left: 0;
    padding-right: 0;
  }

  .content-tab.active,
  .content-tab:active,
  .content-tab:hover {
    color: $kf-highlight;
    text-decoration: none;
    border-style: solid;
    border-color: $kf-highlight;
    border-width: 0 0 2px 0;
  }
}
