.thumb-aspect-big {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 66%;

  @media (max-width: 768px) {
    padding-bottom: 75%;
  }

  @media (max-width: 480px) {
    padding-bottom: 75%;
  }
}

.thumb-aspect {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 75%;
}

.kf-mini-images-item {
  img {
    width: 170px;
  }

  p {
    width: 100%;
    font-size: 0.75em;
    text-align: left;
    margin-top: 0.5em;
  }
}
