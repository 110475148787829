.site-footer {
  position: inherit !important;
  background-color: $kf-highlight;

  @media (max-width: 480px){
    padding-bottom: 60px;
  }

  .site-info {
    h3 {
      color: $kf-contrast;
      font-family: $font-system;
      text-align: left;
      font-size: 1.25em;
      font-weight: 300;
      letter-spacing: 0.05em;
      padding-bottom: 0.3em;
      text-transform: none;
    }
  }

  input[type="text"] {
    background-color: $kf-highlight;
    border-bottom: 1px solid $kf-contrast;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    color: $kf-contrast;
    margin-bottom: 2em;
    width: 20em;
    margin-right: 1em;
  }

  .subtext {
    font-family: $font-headline;
    font-size: (14 / 13) * 1em;
    color: #fff;
    margin-bottom: 1em;
  }

  .btn-kf-footer {
    background-color: $kf-contrast;
    border-radius: 1.2em;
    font-size: 1em;
    color: $kf-highlight;
    text-transform: uppercase;
    padding: 0.8em 1.5em;
  }

  input::placeholder {
    color: $kf-contrast;
  }

  ul {
    &.meta-nav {
      list-style: none;
      padding: 0;

      @media (max-width: 768px){
        margin-top: 10px;
      }

      li.icons {
        display: inline;
        padding-right: 2em;
      }

      li {
        display: block;
        padding-right: 2em;
        padding-right: 1em;
        float: left;

        /* stylelint-disable no-descending-specificity */
        a {
          color: $kf-contrast;
          text-decoration: none;
          font-size: 0.875em;
          font-weight: 300;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      li.active {
        a {
          text-decoration: underline !important;
        }
      }
    }

    &.vorteile {
      padding: 0;
      margin: 10px 0 20px 0;

      li {
        color: $kf-alternate;
        padding: 0;
        margin: 0;
        font-size: 0.875em;
        font-weight: 300;
        margin-bottom: 5px;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .newsletter-footer {
    @media (max-width: 800px){
      padding-top: 30px;
    }

    p {
      margin-top: 20px;
      color: $kf-contrast;
      font-size: 0.875em;
      font-weight: 300;

      a {
        text-decoration: underline;
      }
    }

    .gform_wrapper {
      max-width: 300px;
      margin-left: 0;
    }

    .gfield {
      margin-bottom: 0 !important;
    }

    .gform_fields {
      flex-direction: column !important;
    }

    input[type="submit"] {
      color: $kf-highlight;
      border-color: #fff;
      background-color: #fff;
      margin-top: 0 !important;
      width: auto;
    }

    .gform_confirmation_message {
      color: $kf-contrast;
      font-size: 0.875em;
      font-weight: 300;
    }
  }

  .row {
    padding-top: 3em;
    padding-bottom: 50px;

    &:last-of-type {
      padding-top: 0;
    }
  }

  .icons {
    img {
      vertical-align: sub;
      max-width: 19px;
    }
  }
}
