.breadcrumb {
  background-color: transparent;
  padding: 0;

  .bc-link {
    margin-right: 0.25em;
  }

  a, span {
    font-size: 0.8em;
  }

  .divider {
    margin-right: 0.25em;
  }
}
