.result-count {
  padding: 10px 0 20px 0;
  font-size: 1.125em;
  color: $grey_767676;
  font-family: $font-light;
}

.kf-search-research-filter {
  .label {
    padding-left: 0;
    margin-left: 0 !important;

    &.ml-auto {
      margin-left: auto !important;
    }
  }
}

.personen-artikel {
  .kf-front-misc-articles:first-of-type .section-title {
    margin-top: 0;
  }
}

#grossaus-panel,
#institutionen-panel {
  .kf-front-misc-articles .kf-mini-item h1 {
    font-family: $font-system !important;
  }
}

figure.wp-caption {
  position: relative;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-bottom: 5px;
  }

  .wp-caption-text {
    color: #000;
    font-size: 0.75em;
    font-weight: 300;
    line-height: 1.3em;
    padding: 0 0 60px 0;
    text-align: left;
  }
}
