.shibboleth-lp {
  h5 {
    font-size: 1.5em;
    font-family: 'SabonNextLTPro-Regular', serif;
    margin-top: 2em;
  }

  p {
    max-width: 82%;
  }

  a {
    font-size: 1em;
    margin-top: 15px;
    text-decoration: underline;
    font-weight: 300;
    text-transform: uppercase;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.5em;
    font-family: "SabonNextLTPro-Regular", serif;
    margin-top: 1em;
  }

  .line-sp {
    margin-top: 3em;
    border-top: #9b9b9b 1px solid;
    width: 90%;
  }

  select {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0 !important;
    font-weight: 300 !important;
    margin-top: 2px !important;
    width: 70%;
    margin-right: 30px;
    font-size: 20px;
    outline: none !important;
  }

  input[type="submit"] {
    display: inline-block;
    border-radius: 13px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.8em;
    padding: 1px 1.5em 0;
    letter-spacing: 0.05em;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $white;
    border: 1px solid $black;
    background: $black;
  }


  .bootstrap-select {
    width: 440px !important;

    &::before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 1px;
      z-index: 99;
      color: #ff3e00;
    }

    a {
      font-size: 0.875em;
      margin-top: 15px;
      text-decoration: none;
      font-weight: 300;
      text-transform: none !important;
    }

    button {
      border: 1px solid black;
      outline: none !important;
      border-width: 0 !important;
      border-bottom-width: 1px !important;
      border-radius: 0 !important;
    }
  }

  .dropdown-menu {
    max-height: 375px !important;

    .bs-searchbox {
      width: 100% !important;
    }

    span.text {
      font-size: 16px !important;
    }

    ul.inner {
      li:first-child {
        display: none !important;
      }
    }
  }

  form {
    margin-top: 2em !important;
  }

  .dropdown-toggle {
    font-size: 16px !important;
    font-weight: 300 !important;
  }

  @media only screen and (max-width: 600px) {
    select {
      width: 95%;
      margin-bottom: 1em;
    }

    input[type="submit"] {
      float: right;
      margin-right: 21px;
      margin-top: 5px;
    }

    .line-sp {
      border-top: #9b9b9b 1px solid;
      width: 93%;
      margin-top: 7em;
    }

    h5 {
      margin-top: 1em;
    }

    .bootstrap-select {
      width: 94% !important;
    }

    form {
      margin-top: 3em !important;
    }

    .info-box {
      margin-top: 3em;
    }

    .dropdown-toggle {
      font-size: 14px !important;
    }
  }
}
