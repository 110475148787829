.remodal {
  max-width: 90%;
  width: 1024px;
  height: fit-content;
  padding: 0;
  text-align: left;

  .remodal-close {
    left: auto;
    right: 0;
    width: 48px;
    height: 48px;

    &::before {
      content: "\2573";
      width: 48px;
      font-size: 32px;
      line-height: 48px;
    }
  }

  .remodal-content {
    padding: 40px;

    .border-black {
      border: 0;
      border-right: 1px $black solid;
    }

    .band-popup-cover {
      .all-issues-cover img {
        max-height: 400px;
      }

      .all-issues-number {
        padding: 1.25em 0 0.5em;

        span {
          font-size: 1.875em;
          font-family: $font-highlight;
          color: $kf-highlight-light;
          text-shadow: 0 -1px #000, 1px 0 #000, 0 1px #000, -1px 0 #000;
        }
      }

      .titelthema {
        margin-bottom: 0.75em;
        margin-top: 0.25em;

        .all-issues-title {
          font-family: $font-headline;
          font-size: 1.25em;
          line-height: 1em;
          margin-bottom: 0.25em;
          color: #000;
        }

        .all-issues-subtitle {
          font-size: 1.125em;
          line-height: 1.15em;
          font-weight: 300;
          color: #000;
        }
      }

      .product_readmore {
        margin-top: 16px;

        a {
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
    }

    .band-popup-content {
      padding: 0 24px 64px;
      text-align: center;

      p {
        margin-bottom: 16px;
        font-size: 16px;

        &.band-popup-headline {
          font-family: $font-headline;
          font-size: 24px;
        }
      }

      div.band-popup-advantage {
        color: $kf-highlight;
      }
    }

    .band-popup-side {
      padding: 0 0 64px 24px;
      text-align: center;

      p {
        font-size: 16px;
      }
    }

    .band-popup-advantage {
      margin-top: 48px;
      font-size: 14px;
    }

    .band-popup-price {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }
}

@media (max-width: 767px) {
  .remodal {
    .remodal-content {
      padding: 16px 32px;

      .border-black {
        border: 0;
        border-top: 1px $black solid;
        border-bottom: 1px $black solid;
      }

      .band-popup-cover {
        text-align: center;
        margin-bottom: 32px;

        .all-issues-cover {
          img {
            max-height: 200px;
          }
        }
      }

      .band-popup-content,
      .band-popup-side {
        width: 100%;
        padding: 16px 0;
      }
    }
  }
}

@media (min-width: 768px) AND (max-width: 991px) {
  .remodal {
    .remodal-content {
      .band-popup-cover {
        text-align: center;
        margin-bottom: 32px;

        .all-issues-cover {
          img {
            max-height: 200px;
          }
        }
      }

      .band-popup-content,
      .band-popup-side {
        padding: 0 24px;
      }
    }
  }
}
