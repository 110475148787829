body.single-dossier {
  .wrap, .content, .bgw {
    background-color: #fff;
  }

  .header-headline {
    max-width: 850px;
  }

  h5.section-title {
    margin-top: 40px;
  }

  .kfi-header-text .kfi-header-text-content .img-col {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      margin-bottom: 2em;
    }
  }

  .probelesen {
    span.probe_title_kunstforum {
      font-size: 2.2em;
    }

    .subtitle {
      font-family: $font-system;
      font-size: 1.56em;
      font-weight: 300;
    }

    .subtext {
      font-family: $font-light;
      font-size: 1.25em;

      li {
        margin-bottom: 0.5em;
      }
    }
  }

  p.band-excerpt {
    font-size: 1.25em;
    font-family: $font-headline;
    font-weight: 300;
    margin-bottom: 1em;

    .toggleText {
      color: $grey_9B9B9B;
      font-size: 0.75em;
      font-family: $font-system;
      text-decoration: underline;
    }

    .hideText {
      color: $grey_9B9B9B;
      font-size: 0.75em;
      font-family: $font-system;
      text-decoration: underline;
    }
  }

  #titleArticles,
  #moreArticles,
  #moreNachrichten,
  #moreRecommendations {
    padding-bottom: 0 !important;

    .carousel-inner {
      margin-left: 30px !important;
      width: calc(100% - 60px) !important;
    }

    h4 {
      word-break: break-word;
    }
  }

  #moreRecommendations {
    margin-bottom: 30px;
  }

  #moreNachrichten,
  #moreRecommendations {
    .nachrichten-date {
      font-size: 0.75em;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    h4 {
      font-family: $font-system;
      font-weight: 300;
      font-size: 1.875em;
      padding: 0 0 10px 0;
    }
  }

  .postcontent {
    padding-top: 0 !important;

    .content-slider {
      width: 100%;
      padding: 0 !important;

      .slick-slide, .teaser-large {
        height: intrinsic;
      }

      .slick-next {
        right: 0 !important;
      }

      .slick-prev {
        left: 0 !important;
      }
    }
  }

  .kf-all-issues .all-issues-number {
    padding: 0 0 0.5em;
  }
}
