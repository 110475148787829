.template-nachrichten {
  .nachrichten-pagination {
    display: flex;
    justify-content: center;
    padding: 4em 0 7em;

    .page-numbers {
      border: 1px solid $grey_ddd;
      color: $kf-highlight;
      border-radius: 1em;
      padding: 0.9em 1.2em;
      margin: 0 0.4em;
      font-size: 0.875em;
      font-weight: 500;
      line-height: 100%;

      &.current,
      &.prev,
      &.next {
        color: #000;
        font-weight: 300;
      }
    }
  }

  .titelstories {
    flex-wrap: wrap;
    padding-top: 0;

    .story img {
      align-self: center;
      width: 100%;
      margin-bottom: 0.5em;
    }

    .story h2 {
      font-size: 1.875em;
      font-family: $font-system;
      line-height: 1.1em;
      margin-top: 10px;
    }

    .story p {
      font-size: 1.125em;
      line-height: 1.5em;
      margin-bottom: 0;
    }

    .story a > span {
      font-size: 0.75em;
      margin-bottom: 0.25em;
    }

    .story {
      margin-bottom: 5em;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }

    .story-infobox {
      .story-content {
        border: 1px solid $kf-highlight;
        padding: 5px 10px;
      }
    }

  }

  .nachrichten-wrapper {
    padding-top: 5em;

    .filter {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .btn-group:first-child {
        margin-right: 1em;
      }
    }

    .nachrichten-list {
      > p {
        font-weight: bold;
        margin: 4em 0;
      }

      .nachricht {
        padding: 2.5em 0;
        border-bottom: 1px solid $grey_ddd;

        img {
          max-width: 100%;
        }

        h2 {
          font-size: 1.875em;
          font-family: $font-system;
          line-height: 1.1em;
          margin-top: 8px;
        }

        p {
          font-size:  1.125em;
          line-height: 1.5em;
          margin-bottom: 0;
          margin-top: 40px;
        }

        span {
          font-size: 0.75em;

          > span {
            font-size: 1em;
          }
        }
      }
    }
  }

  .inline-newsletter-holder {
    margin-bottom: 60px;
  }

  .anzeigen-list {
    padding-bottom: 4em;

    .anzeige {
      .anzeige-label {
        color: $grey_9B9B9B;
        font-size: 0.75em;
        padding-top: 1.5em;
        padding-bottom: 0.5em;
        display: block;
      }

      .anzeige-bild {
        border: 1px solid $kf-alternate;

        img {
          width: 100%;
        }
      }
    }
  }

}

.single-nachrichten {
  .wp-caption {
    max-height: 600px;
    margin: 30px auto 60px auto !important;

    .size-full {
      width: auto !important;
      max-height: 600px;
    }

    &::after {
      bottom: -20px !important;
    }
  }

  .floating-sticky {
    z-index: 20 !important;
  }

  #titleArticles,
  #moreArticles,
  #moreNachrichten,
  #moreRecommendations {
    padding-bottom: 0 !important;

    .carousel-inner {
      margin-left: 30px !important;
      width: calc(100% - 60px) !important;
    }

    h4 {
      word-break: break-word;
    }
  }

  #moreNachrichten,
  #moreRecommendations {
    .nachrichten-date {
      font-size: 0.75em;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    h4 {
      font-family: $font-system;
      font-weight: 300;
      font-size: 1.875em;
      padding: 0 0 10px 0;
    }
  }

  #moreArticles {
    padding-bottom: 60px !important;
  }
}
