.cbxwpbkmarkwrap {
  height: 27px;
  width: 27px;
  padding-left: 0;
  border: none;
  margin-top: -0.25em;

  .addto-head {
    height: auto;
    text-align: left;
    font-weight: bold;

    i {
      font-style: normal;
    }
  }

  > div {
    border: 1px solid #ff3e00;
    bottom: auto !important;
    top: 40px !important;
    right: 0;
    left: auto;
  }

  .cbxlbjs-searchbar-wrapper,
  .cbxwpbkmarkmanagecatselect,
  .cbxwpbkmarkaddnewcatselect,
  .cbxlbjs-searchbar-wrapper-manage {
    display: none;
  }

  input[type="text"].cbxwpbkmarkaddnewcatinput, input[type="text"].cbxwpbkmarkmanagecatinput {
    outline: none !important;
  }

  .cbxwpbkmarkaddnewcat {
    padding-top: 10px;

    > a {
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .cbxlbjs-item-name {
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 480px) {
      width: 190px;
    }
  }

  .cbxlbjs-item {
    .cbxlbjs-item-name {
      font-size: 0.875em;
      font-weight: 500;
    }

    &::before {
      background: none !important;
      background-size: auto;
      width: auto !important;
      height: auto !important;
      content: "";
      border: 1px solid #9b9b9b;
      padding: 1px 4px 0 5px;
      margin-right: 0.5em;
    }
  }

  .cbxlbjs-item-manage {
    text-align: left;
    font-weight: 500;
    width: 100% !important;

    .cbxlbjs-item-name-manage {
      width: 100%;
    }

    &::before {
      display: none;
    }
  }

  .cbxlbjs-item[data-incat="0"] {
    &::before {
      background: none !important;
      background-size: auto;
      width: auto !important;
      height: auto !important;
      content: "-";
      border: 1px solid #9b9b9b;
      padding: 0 7px 1px 7px;
      margin-right: 0.5em;
    }

    &::after {
      display: none;
    }
  }

  .cbxlbjs-item[data-incat="1"] {
    &::before {
      background: none !important;
      background-size: auto;
      width: auto !important;
      height: auto !important;
      content: "\2713";
      border: 1px solid #9b9b9b;
      padding: 1px 4px 0 5px;
      margin-right: 0.5em;
    }

    &::after {
      display: none;
    }
  }

  .cbxwpbkmarktrig_close {
    background-image: url(../images/icon_close_x_kfi.svg) !important;
    background-size: 60% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .cbxwpbkmarktrig-button-addto {
    z-index: 999999;

    &::before {
      content: '';
      display: block;
      background-image: url(../images/bookmark.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 27px;
      width: 27px;
      margin-top: -0.25em;
    }
  }

  .cbxwpbkmarktrig-marked {
    &::before {
      content: '';
      display: block;
      background-image: url(../images/bookmark-filled.svg) !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 27px;
      width: 27px;
      margin-top: -0.25em;
    }
  }


  .cbxwpbkmarktrig-label {
    display: none;
  }

  .cbxwpbkmarkguestwrap,
  .cbxwpbkmarklistwrap {
    width: 300px;
    z-index: 99999;

    @media (max-width: 480px){
      width: 240px;
    }
  }

  .cbxwpbkmarkguestwrap {
    border: 1px solid #ff3e00;

    .cbxwpbkmarkguest-message {
      padding: 1em;
      text-align: left;
      font-weight: 300;

      span {
        text-decoration: underline;
      }

      .cbxwpbkmarkguesttrig_close {
        display: none;
      }
    }
  }
}
