@import 'common/variables';

/** Import everything from autoload */
@import './autoload/_bootstrap.scss'; @import './autoload/_fontawesome.scss'; @import './autoload/_photoswipe.scss';

// Slick Styles
@import "~slick-carousel/slick/slick.css";

// Slick Theme
@import "~slick-carousel/slick/slick-theme.css";

// Fancybox
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

/** 3rd party **/
@import './3rdparty/bootstrap-select/bootstrap-select';

// Remodal
@import '~remodal/dist/remodal.css';
@import '~remodal/dist/remodal-default-theme.css';

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

// common used variables
@import 'common/fonts';
@import 'common/breakpoints';
@import 'common/global';
@import 'common/mediaqueries';

// mixins

// components
@import 'components/buttons';
@import 'components/comments';
@import 'components/forms';
@import 'components/navbar';
@import 'components/metanav';
@import 'components/breadcrumb';
@import 'components/wp-classes';
@import 'components/caroussel';
@import 'components/stage';
@import 'components/timeline';
@import 'components/bookmark';
@import 'components/tabs';
@import 'components/tables';
@import 'components/lightbox';
@import 'components/grids';
@import 'components/paywall';
@import 'components/paywall-new';
@import 'components/anzeige';
@import 'components/featherlight';
@import 'components/popit';
@import 'components/flipbook';
@import 'components/remodal';


// layouts
@import 'layouts/layout';
@import 'layouts/kf-start';
@import 'layouts/postcontent';
@import 'layouts/startseite';
@import 'layouts/abonnement';
@import 'layouts/nl-abonnieren';
@import 'layouts/nachrichten';
@import 'layouts/kundencenter';
@import 'layouts/static-pages';
@import 'layouts/archiv';
@import 'layouts/single-band';
@import 'layouts/single-dossier';
@import 'layouts/single-nachrichten';
@import 'layouts/single-abonnement';
@import 'layouts/single-entities';
@import 'layouts/suche';
@import 'layouts/recherche';
@import 'layouts/header';
@import 'layouts/sidebar';
@import 'layouts/footer';
@import 'layouts/pages';
@import 'layouts/posts';
@import 'layouts/tinymce';
@import 'layouts/kf-article';
@import 'layouts/shibboleth';
