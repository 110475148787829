input[type="submit"],
a {
  color: inherit;

  &:link,
  &:visited,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.txt-link {
    text-decoration: underline;
  }

  &.kf-btn,
  &.kf-btn-alt,
  &.kf-btn-white,
  &.kf-btn-black,
  &.kf-btn-outline {
    display: inline-block;
    border-radius: 13px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.8em;
    padding: 1px 1.5em 0;
    letter-spacing: 0.05em;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  &.kf-btn {
    background-color: $kf-highlight;
    border-color: $kf-highlight;
    color: $kf-contrast;
  }

  &.kf-btn-alt {
    color: #212529;
    border: 1px solid #212529;
  }

  &.kf-btn-outline {
    color: $kf-highlight;
    border: 1px solid $kf-highlight;
  }

  &.kf-btn-black {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }

  &.kf-btn-white {
    color: $kf-highlight;
    background-color: $kf-contrast;
    border-color: $kf-contrast;
  }
}
