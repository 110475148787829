.postcontent, .article-body {
  padding-top: 100px !important;
  padding-bottom: 30px !important;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;

  @media (max-width: 1000px) {
    padding-top: 0 !important;
  }

  div.wp-video {
    margin: 0 auto;
  }

  > p {
    margin: 0 auto 1.5em auto;
    max-width: 80%;
    width: 600px;
    font-family: $font-headline;
    font-size: 1.25em;
    margin-bottom: 2em;
    line-height: 1.4em;

    @media (min-width: 1200px) {
      max-width: 600px;
      width: 600px;
    }

    @media (min-width: 1440px) {
      max-width: 750px;
      width: 750px;
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      width: 500px;
      max-width: 500px;
    }

    @media (max-width: 480px){
      max-width: calc(100% - 20px);
    }

    em {
      font-style: initial;
      font-family: $font-system;

      &.italic {
        font-style: italic !important;
      }

      &.interviewFrage {
        font-family: $font-system;
        font-style: initial;
      }
    }

    &.frage {
      margin-bottom: 0;
      font-style: initial;
      font-family: $font-system;

      + p {
        text-indent: 2em;
      }
    }

    &.antwort {
      text-indent: 2em;
    }

    &.lead-in {
      font-style: initial;
      font-family: $font-system;
    }
  }

  > .bio, > .annotation {
    font-family: $font-system;
    font-size: 1.25em;
    line-height: 1.4em;
    max-width: 80%;
    width: 650px;
    margin: 0 auto 2em;

    @media (min-width: 1200px) {
      max-width: 600px;
      width: 600px;
    }

    @media (min-width: 1440px) {
      max-width: 750px;
      width: 750px;
    }

    @media (min-width: 1000px) and (max-width: 1100px) {
      width: 500px;
      max-width: 500px;
    }

    @media (max-width: 480px) {
      width: calc(100% - 20px);
      max-width: calc(100% - 20px);
    }

    * {
      font-family: $font-system;
      font-size: 1em;
    }
  }

  > h1, > h2, > h3, > h4, > h5, > h6, {
    max-width: 80%;
    width: 650px;

    @media (min-width: 1200px) {
      max-width: 600px;
      width: 600px;
    }

    @media (min-width: 1440px) {
      max-width: 850px;
      width: 850px;
    }

    @media (max-width: 768px) {
      max-width: 90%;
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      width: 500px;
      max-width: 500px;
    }

    @media (max-width: 480px) {
      width: calc(100% - 20px);
      max-width: calc(100% - 20px);
    }

    margin: 0 auto;
    text-align: center;
  }

  > .quote {
    font-family: $font-headline;
    font-size: 3em;
    color: #000;
    padding: 0;
    margin-bottom: 1em;
    margin-top: 2em;
    max-width: 80%;
    width: 650px;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
    }
  }

  /* h1 {
    font-family: $font-system;
    color: #000;
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    width: 140%;
    padding: 30px 0 30px 0;
  }

  h2 {
    font-family: $font-headline;
    color: #000;
    font-size: 3.75em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    padding-bottom: 60px;
  }

  h3 {
    font-family: $font-system;
    color: #000;
    font-size: 2em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    padding-bottom: 0.5em;
  } */

  h1 {
    padding-bottom: 60px;
    font-family: $font-headline;
    font-size: 3.75em;
    font-weight: 300;
    color: #000;
    line-height: 1.1em;
    text-align: center;

    small {
      display: inline-block;
      padding: 30px 0 30px 0;
      font-family: $font-system;
      font-size: 0.4em;
      font-weight: 300;
      line-height: 1.1em;
    }
  }

  h2 {
    font-family: $font-system;
    color: #000;
    font-size: 2em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    padding-bottom: 0.5em;
  }

  h3 {
    font-family: $font-system;
    color: #000;
    font-size: 2em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    padding-bottom: 0.5em;
  }

  h4 {
    font-family: $font-headline;
    color: #000;
    font-size: 2em;
    font-weight: 300;
    line-height: 1.25em;
    text-align: center;
    padding-bottom: 30px;
    width: 140%;
    margin: 0 auto;
  }

  // h5 {
  //   width: 140%;
  //   padding: 30px 0 30px 0;
  //   font-family: $font-headline;
  //   text-align: center;
  // }

  h3 + h4 {
    margin-top: 30px;
    margin-bottom: 60px;
    font-family: $font-system;
  }

  h5 {
    font-family: $font-system;
    color: #000;
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.1em;
    text-align: center;
    width: 140%;
    padding: 30px 0 30px 0;

    &.section-title {
      width: 100% !important;
      color: #ff3e00 !important;
      font-size: 1.15em !important;
      font-weight: 400 !important;
      margin-bottom: 0 !important;
      margin-top: 80px !important;
      padding-bottom: 2em !important;
      text-align: center !important;
      text-transform: uppercase !important;
    }

  }

  .annotation {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.875em;
    color: #666;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
    }
  }

  .bio {
    color: #666;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    background-color: #f2f2f2;
    padding: 30px;
    font-size: 0.875em;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
      padding: 15px;
    }
  }

  .article_h1,
  .article_subtitle {
    display: block !important;
    max-width: 80%;
    width: 650px;
    margin: 0 auto;
  }

  .content-slider {
    background-color: #fff;
    padding: 30px 60px 30px 60px;
    //z-index: 9999;
    position: relative;
    width: calc(100% + 120px);

    @media (max-width: 1200px) {
      left: -30px;
      width: calc(100% + 60px);
      max-width: calc(100% + 60px);
    }

    @media (max-width: 800px) {
      max-width: calc(100% + 90px);
      width: calc(100% + 90px);
    }

    @media (max-width: 480px) {
      max-width: calc(100% + 90px);
      width: calc(100% + 90px);
      left: -35px;
    }

    &.single-image {
      background-color: transparent;
    }

    &.slick-dotted {
      padding-bottom: 60px;
    }

    .slick-prev,
    .slick-next {
      z-index: 2;
      height: 64px;
      width: 32px;
      top: calc(50% - 32px);

      @media (max-width: 480px) {
        transform: scale(0.7);
      }

      &.slick-disabled {
        &::before {
          opacity: 0.2 !important;
        }
      }
    }

    .slick-prev {
      left: 60px !important;

      &::before {
        background-image: url(../images/icon-arr-left-black.svg) !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        content: '';
        height: 64px;
        display: block;
        width: 32px;
        opacity: 1;
      }
    }

    .slick-next {
      right: 60px !important;

      &::before {
        background-image: url(../images/icon-arr-right-black.svg) !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        content: '';
        height: 64px;
        display: block;
        width: 32px;
        opacity: 1;
      }
    }

    .slick-dots {
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 80%;
      width: 600px;

      @media (min-width: 1200px) {
        max-width: 600px;
        width: 600px;
      }

      @media (min-width: 1440px) {
        max-width: 750px;
        width: 750px;
      }

      @media (min-width: 1000px) and (max-width: 1100px) {
        width: 500px;
        max-width: 500px;
      }
    }

    .slick-list {
      padding: 0 30px !important;
      margin: 0;
      width: 100%;

      .slick-track {
        display: flex;
        align-items: center;

        .slick-slide {
          padding: 0 30px;
        }
      }
    }
  }

  figure.wp-caption {
    position: relative;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }

    .wp-caption-text {
      color: #666;
      font-size: 0.875em;
      font-weight: 300;
      padding: 10px 30px 0 10px;
    }

    &::after {
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      bottom: 6px;
      right: 1em;
      position: absolute;
      background-image: url(../images/icon-lightbox.svg) !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }

  .lazyload-img {
    position: relative;
    min-height: 200px;

    > img {
      height: auto;
      max-width: 100%;
    }

    &.loading {
      &::before {
        background-image: url(../images/loading-puff.svg) !important;
        background-size: 80px !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255,255,255,0.8);
        z-index: 999;
      }
    }
  }

  figure.wp-caption img {
    width: 100% !important;
    margin-bottom: 0;
    max-width: 100%;
    height: auto;
  }

  .floating-infobox {
    padding: 2em 1em;
    background: #fff;
    border: 1px solid $kf-highlight;
    margin-bottom: 1em;

    a:hover span {
      text-decoration: underline;
    }

    .nachricht-infobox {
      max-width: 100%;

      .nachricht-infobox-title {
        font-family: $font-system;
        font-weight: 300;
        font-size: 1.3em;
        line-height: 1em;
        display: inline-block;
        margin-bottom: 0.5em;
      }

      .nachricht-infobox-text {
        margin-top: 1em;
      }
    }
  }

  .static-sticky {
    margin: 0;
    z-index: 10000;
    opacity: 1;
    height: 0 !important;
    transition: 0.3s opacity ease-in-out;
    position: relative;

    @media (max-width: 1000px) {
      display: none;
    }

    .floating-inner {
      position: absolute;
      // right: 30px;
      // top: 0;
      // width: 200px !important;
      top: -10px;
      right: 0;
      width: 220px;
      padding: 0 30px 0 10px;
      background-color: #fff;

      .article_rubriken {
        padding-bottom: 0;
        margin-top: 15px;
        line-height: 1.2em;
        text-align: right;
        width: 100%;
        font-weight: 300;
      }

      .article_meta {
        padding-top: 10px;
        padding-bottom: 0;
        line-height: 1.2em;
        text-align: right;
        font-weight: 300;
        width: 100%;
      }

      > .row {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .floating-issue {
      padding: 2em 0;
      background: #fff;
      border: 1px solid #000;

      .btn-wrapper {
        padding: 1rem 0.5rem 0;

        .btn {
          width: 100%;

          & + .btn {
            margin-top: 10px;
          }
        }
      }

      a:not(.btn) {
        font-size: 1.125em;

        .img-fluid {
          width: calc(100% - 4em);
          margin-bottom: 2em;
        }

        p.head {
          margin: 0;
          font-size: 1em;
          line-height: 1.5em;
        }

        p.name,
        p.link {
          margin: 0;
          font-family: $font-system;
          line-height: 1.2em;
          text-align: center;
          font-size: 0.8125em;
        }

        p.link {
          margin-top: 10px;
          text-decoration: underline;
        }

        &:hover {
          p.link {
            text-decoration: none;
          }
        }
      }
    }
  }

  .floating-sticky {
    margin: 0;
    z-index: 1000;
    opacity: 1;
    height: 0 !important;
    transition: 0.3s opacity ease-in-out;
    position: relative;

    @media (max-width: 1000px) {
      display: none;
    }

    .floating-inner {
      position: absolute;
      top: 0;
      // right: 0;
      // width: 200px !important;
      right: -30px;
      width: 220px !important;
      padding: 0 30px 0 10px;
      background: #fff;

      hr.meta-spacer {
        width: 100%;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      > .row {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.is-bottom {
      opacity: 0 !important;
      visibility: hidden;
    }

    &.fixed {
      position: fixed;
      top: 0;
      opacity: 0.2;
      z-index: 1;

      &:hover {
        opacity: 1;
      }

      .floating-inner {
        top: 80px !important;
      }
    }
  }
}

.single-artikel {
  .article-relations {
    padding-bottom: 0 !important;

    .tab-pane {
      padding: 30px 0 !important;

      @media (max-width: 768px){
        padding: 60px 0 60px !important;
      }

      @media (max-width: 480px) {
        padding: 30px 0 0 !important;
      }
    }

    .letter-row {
      .section-title {
        margin-top: 0;

        @media (max-width: 480px) {
          padding-bottom: 10px;
          margin-top: 20px;
        }
      }

      .person {
        font-size: 1.5em;
      }
    }
  }

  #titleArticles,
  #moreArticles,
  #moreNachrichten,
  #moreRecommendations {
    padding-bottom: 0 !important;

    .carousel-inner {
      margin-left: 30px !important;
      width: calc(100% - 60px) !important;
    }

    h4 {
      font-size: 1.875em;
      word-break: break-word;
    }
  }
}
