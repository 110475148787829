.template-kundencenter {
  .content-tab_style {
    position: relative;

    .inline-link {
      font-size: 0.875em;
      text-decoration: underline;
      font-weight: 400;
    }

    .ml-auto {
      position: absolute;
      right: 0;
    }
  }

  .tab-content {
    .tab-inner {
      width: 635px;
      max-width: 90%;
      padding-top: 4em;
      padding-bottom: 10em;
      margin: 0 auto;
    }

    #password-panel,
    #email-panel,
    #rechnungen-panel {
      padding-top: 0;

      h4 {
        color: #000;
        font-size: 2em;
        font-family: $font-headline;
        line-height: 1em;
        padding: 0 0 0.25em 0;
        margin: 0 0 0.5em 0;
      }

      p {
        font-size: 1.125em;
        max-width: 90%;
      }

      a.kf-btn {
        margin-top: 10px;
      }

      form {
        max-width: 90%;
        width: 628px;
        display: flex;
        flex-direction: column;
      }
    }

    #merkliste-panel {
      padding: 3em 0;

      h4 {
        color: $kf-highlight;
        font-size: 0.875em;
        font-weight: 300;
        position: relative;

        .cbxwpbkmarkmanagecatdelete {
          background: none;
          color: $kf-highlight;
          text-transform: none;
          text-decoration: underline;
          padding: 0;
        }
      }

      .cbxwpbookmark-mylist {
        padding: 0;
        margin: 0 0 2em 0;

        li {
          list-style: none;
          border-top: 1px solid $grey_ddd;
          padding: 1em 0;
          position: relative;

          .merkliste-item {
            font-weight: 300;
            display: block;
            font-size: 1em;
            padding-right: 3em;

            .item-content-col {
              display: flex;
              flex-direction: column;
            }

            img {
              width: 100%;
            }

            h4 {
              color: #000;
              font-size: 2em;
              font-family: $font-headline;
              line-height: 1em;
              padding: 0 0 0.25em 0;
              margin: 0;

              @media (max-width: 480px) {
                margin-top: 20px;
              }
            }

            h5 {
              color: black;
              text-transform: none;
              text-align: left;
              font-size: 1.125em;
              font-weight: 300;
              padding-bottom: 1em;
              margin-bottom: 0;
            }

            .mt-auto {
              font-size: 0.8em;
              font-weight: 400;
              line-height: 1.35em;

              @media (max-width: 480px) {
                margin-bottom: 10px;
              }
            }
          }

          .merkliste-item:hover {
            h4, h5, span {
              color: $kf-highlight;
            }
          }

          .cbxbookmark-post-delete {
            position: absolute;
            right: 0;
            top: 50%;
            height: 27px;
            width: 27px;
            transform: translate(-50%, -50%);

            &::before {
              background-image: url(../images/icon_close_x_black.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 27px;
              width: 27px;
            }
          }

          .cbxbookmark-post-delete:hover {
            &::before {
              background-image: url(../images/icon_close_x_kfi.svg);
            }
          }

        }
      }

      .cbxbookmark-category-list {
        padding: 0;
        margin: 0 0 2em 0;

        li {
          list-style: none;
          border-top: 1px solid $grey_ddd;
          padding: 1em 0;

          a {
            font-size: 1.875em;
            font-weight: 300;
            font-family: $font-headline;
          }
        }
      }
    }

  }

  #myTab {
    margin-top: 40px;
  }
}
