.new-paywall {
  #leaky_paywall_message {
    padding: 30px 20px;
  } 

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0;

    @media (max-width: 640px) {
      flex-direction: column;
    }  

    .column {
      max-width: 45%;
      width: 400px;
      padding: 20px 10px 10px;
      display: flex;
      flex-direction: column;
      border: 1px solid $grey_ddd;

      @media (max-width: 768px) {
        width: 280px;
      }  

      @media (max-width: 640px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }  

      &.form-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 20px;

        input[type="submit"] {
          margin-top: 0;
        }

        .leaky-form-title {
          font-family: $font-system;
          text-align: center;
          max-width: 100%;
          font-weight: 300;
          font-size: 1.125em;
          margin: 0 auto;
        }

        .form-holder {
          max-width: 100%;
          width: 90%;
          margin: 0 auto;

          li.gfield {
            margin-bottom: 0 !important;
          }

          .gform_footer {
            text-align: center;
          }

          .gform_wrapper {
            max-width: 100% !important;
          }
        }

        .form-bullets {
          padding: 20px;
          margin: 0;

          .form-bullet {
            font-size: 0.875em;
            font-weight: 300;
            list-style: none;
            margin: 0 0 15px;
            padding: 0 0 0 1.5em;
            position: relative;

            &::before {
              content: "\2713";
              left: 0;
              position: absolute;
              top: 0;
            }
          }
        }
      }

      &.abo-column {
        .abo-img {
          max-width: 240px;
          margin: 0 auto 10px;
          display: block;
        }

        .abo-name {
          font-family: $font-system !important;
          text-align: center;
          font-weight: 300;
          font-size: 1.25em !important;
          margin: 0 auto;
          max-width: 80%;
          padding: 0;
        }

        .abo-bullets {
          padding: 20px;
          margin: 0 !important;
          border-top: 1px solid $grey_ddd;
          border-bottom: 1px solid $grey_ddd;

          .abo-bullet {
            font-size: 1em !important;
            font-weight: 300;
            list-style: none;
            margin: 0 0 15px;
            padding: 0 0 0 1.5em;
            position: relative;

            &::before {
              content: "\2713";
              left: 0;
              position: absolute;
              top: 0;
            }
          }
        }

        .abo-preis {
          font-size: 1.25em !important;
          line-height: 1em;
          font-weight: 100;
          margin: 15px 0 10px !important;
          text-align: center;
        }

        .single-button-holder {
          text-align: center;
          margin-top: 0;
          margin-bottom: 10px;

          > .kf-btn {
            font-weight: normal !important;
          }
        }
      }
    }
  }

  #wp-submit {
    margin-top: -10px !important;
  }

  h1 {
    font-family: $font-headline !important;
    font-size: 2em !important;
    margin-bottom: 10px !important;
    padding-top: 10px !important;
    text-align: center !important;
    max-width: 100%;
    padding-bottom: 0 !important;
    width: 100% !important
  }

  h4 {
    font-family: $font-system !important;
    font-size: 1.25em !important;
    margin-bottom: 10px !important;
    text-align: center !important;
    max-width: 100%;
    padding-bottom: 0 !important;
  }

  h3 {
    font-family: $font-system !important;
    font-size: 1.5em !important;
    margin-bottom: 10px !important;
    text-align: center !important;
    max-width: 100%;
    padding-bottom: 0 !important;
  }

  h5 {
    font-family: $font-system !important;
    font-size: 1.125em !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    text-align: center !important;
    max-width: 100%;
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }

  p.pw-subline {
    font-family: $font-system;
    font-size: 1.125em;
    text-align: center !important;
    max-width: 100%;
    padding-bottom: 20px !important;
    font-weight: bold;
  }

  .expand-login-text {
    text-decoration: underline;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      height: 15px;
      width: 15px;
      background-image: url('../images/icon_carousel_arrow_right.svg');
      transform: rotate(90deg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 12px;
      margin-right: 10px;
    }

    &.show {
      &::before {
        transform: rotate(270deg);
      }
    }
  }

  .expand-login-holder {
    display: none;
    max-width: 400px;
    width: 100%;
    padding-top: 10px;

    .login-additonal-text {
      font-size: 0.875em;
    }
  }

  ul {
    margin: 0.5em 0 1.5em;
    padding-left: 2em;

    li {
      font-weight: 300;
      font-size: 1.125em;
      
      p {
        margin: 0;
      }
    }
  }

  hr {
    margin: 2rem 0;
  }

  a.kf-btn {
    color: #fff !important;
    font-weight: 300;
  }

  a.inline-link {
    text-decoration: underline;
    font-size: 0.875em;
    font-weight: 300;
    margin-top: 0.5em;

    + p {
      font-size: 0.875em;
      font-weight: 300;
      margin-top: 0.5em;
    }
  }

  form {
    padding-top: 1em;
    margin-left: 0 !important;

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      font-size: 0.75em !important;
      line-height: 30px !important;
      height: 30px !important;
    }

    .gform_fields {
      padding-left: 0;

      .gfield {
        margin-bottom: 0 !important;
      }
    }

    .kf-btn-alt {
      font-weight: 300;
    }

    .inline-link {
      display: block;
    }

    input#user_login {
      margin-top: 1em;
      margin-bottom: 20px !important;
    }

    input#user_pass {
      margin-bottom: 5px !important;
    }

    input[name="password"] {
      margin-bottom: 0;
    }
  }
}
