.header-shorttext {
  margin-top: 15px;
  color: $kf-highlight;

  a {
    text-decoration: underline;
  }
}

.all-issues-wrapper {
  padding: 0;
  padding-bottom: 0 !important;

  h3 {
    text-align: center;
  }

  .section-title {
    margin-top: 50px;
  }
}

.kf-all-issues {
  padding-bottom: 4em;
  position: relative;

  .carousel-item.band-item {
    padding: 0 30px;
  }

  #issueCarousel {
    width: 100%;
  }

  // @media (min-width: 640px) {
  //   #issueCarousel {
  //     right: 5%;
  //   }
  // }

  .slick-track {
    display: flex;
  }

  > a {
    margin-top: 2em;
  }

  .kf-all-issues-arrow-left {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .kf-all-issues-arrow-right {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    display: flex;
  }

  .band-col {
    margin-top: 2.5em;
  }

  .band-col, .carousel-item {
    .btn {
      display: block;
      width: 175px;
      font-size: 0.8em;
      height: 50px;
      line-height: 50px;
      margin-right: auto;
      margin-left: auto;

      &.kf-btn-alt {
        background-color: #fff;
      }
    }
  }

  .all-issues-number {
    padding: 1em 0 0.5em;

    span {
      font-size: 1.875em;
      font-family: $font-highlight;
      color: $kf-highlight-light;
      text-shadow: 0 -1px #000, 1px 0 #000, 0 1px #000, -1px 0 #000;
    }
  }

  figure.all-issues-cover {
    display: inline-block;
    position: relative;

    figcaption.btn-overlay {
      display: none;

      .btn {
        & + .btn {
          margin-top: 10px;
        }
      }
    }

    &:hover {
      figcaption.btn-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background: rgba(255,255,255,0.5);
      }
    }
  }

  .all-issues-cover {
    img {
      max-height: 400px;
    }
  }

  .titelthema {
    margin-bottom: 0.75em;
    margin-top: 0.25em;
  }

  .all-issues-title {
    font-family: $font-headline;
    font-size: 1.25em;
    line-height: 1em;
    margin-bottom: 0.25em;
    color: #000;
  }

  .all-issues-subtitle {
    font-size: 1.125em;
    line-height: 1.15em;
    font-weight: 300;
    color: #000;
  }
}
