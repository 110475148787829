.carousel {
  width: 100%;

  .caroussel-controls {
    &.outside {
      position: absolute !important;
      width: calc(100% + 80px);
      left: -40px;
      top: 50%;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    position: relative;
    margin-top: -2em;
    width: initial;
    opacity: 1;
  }

  .carousel-control-prev-icon {
    background-image: url('../images/icon_carousel_arrow_left.svg');
  }

  .carousel-control-next-icon {
    background-image: url('../images/icon_carousel_arrow_right.svg');
  }

  .carousel-item .row {
    min-height: 200px;
  }
}

.kf-front-current.issue {
  padding: 5em 0;
}

.news-item-infobox {
  border: 1px solid $kf-highlight;
  padding-top: 15px;

  &:hover .news-item-shorttext{
    text-decoration: underline;
  }

  .news-head {
    margin-bottom: 10px;
  }
}

.kf-front-news {
  background-color: $kf-alternate;
  padding: 0 0 5em 0;

  h3 {
    color: $kf-highlight;
    font-size: 1.15em;
    text-transform: uppercase;
    padding-bottom: 2em;
    font-weight: normal;
    text-align: center;
  }

  .kf-front-news-title {
    display: block;
    font-size: 1em;
  }

  .news-item {
    @media (max-width: 992px){
      margin-bottom: 20px;
    }

    &:hover {
      .news-head,
      .kf-front-news-title {
        text-decoration: underline;
      }
    }
  }

  .carousel-item {
    a:not(.news-item-shorttext) {
      font-size: 1.875em;
      line-height: 1.15em;
      font-weight: 300;
      margin-top: 5px;
      display: inline-block;
      max-width: 100%;
      word-break: break-word;
    }
  }

  .carousel-caption {
    display: block;
    position: relative !important;
    right: 0;
    bottom: -5px;
    left: 0;
    margin-top: 1em;
    padding: 0;
    color: black;
    font-family: $font-headline;
    font-size: 1.25em;
    z-index: 1;
  }

  .position-relative {
    z-index: 2;
  }
}

.slick-slider {
  width: auto;

  .carousel-caption {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: 60px 0 0 0;
    left: 0;

    h5 {
      color: #000;
    }
  }

  .carousel-controls {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 480px){
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 2;
    height: 64px;
    width: 32px;

    &.slick-disabled {
      &::before {
        opacity: 0.2 !important;
      }
    }
  }

  .slick-dots {
    bottom: 1em;
  }

  .slick-prev {
    left: 0;

    &::before {
      background-image: url(../images/icon-arr-left-black.svg) !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      content: '';
      height: 64px;
      display: block;
      width: 32px;
      opacity: 1;
    }
  }

  .slick-next {
    right: 0;

    &::before {
      background-image: url(../images/icon-arr-right-black.svg) !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      content: '';
      height: 64px;
      display: block;
      width: 32px;
      opacity: 1;
    }
  }
}

#newsCarousel {
  .slick-dots {
    position: relative;
    display: block;
    right: 0;
    bottom: -45px;
    left: 0;
    padding: 0;
    margin: 0 auto;
    width: 100px;
    text-align: center;

    span {
      color: black;
      font-family: $font-headline;
      padding-bottom: 0;
      text-align: center;
      font-size: 1.15em;
    }
  }

  .carousel-controls {
    margin-top: 50px;
  }

  .carousel-inner {
    overflow: visible;

    .slick-slide {
      height: auto;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
      }
    }

    .kf-front-news-title {
      span {
        font-size: 0.8em;
      }
    }
  }
}
