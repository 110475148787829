.kf-archive-selector {
  background-color: $grey_f2;
  padding: 0;
  height: 120px;
}

.timeline-sticky {
  background-color: $grey_f2;
  height: 120px;
  padding-top: 2.5em !important;
  padding-bottom: 1.5em !important;
  align-items: center;
  display: flex;
  transition: top 0.2s ease-in-out;

  &.fixed {
    position: fixed;
    top: 0;
    z-index: 99;
  }

  &.timeline-scroll-custom {
    top: 70px;
    transition: top 0.6s ease-in-out;
  }
}

.timeline {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .year {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 33%;
      width: 1px;
      background: $grey_9B9B9B;
      z-index: 1;
    }

    .line {
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      z-index: 2;

      .label {
        position: absolute;
        left: 50%;
        top: -1em;
        transform: translateX(-50%);
        font-size: 0.8125em;
        color: $grey_767676;
        opacity: 0;
      }

      .pin {
        height: 80%;
        width: 2px;
        background-color: $kf-highlight;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: 0.3s opacity ease-in-out;
        
        &::after {
          content: '';
          height: 6px;
          width: 6px;
          position: absolute;
          top: 0;
          left: -2px;
          background-color: $kf-highlight;
          border-radius: 50%;
        }
      }
    }

    &.decade,
    &.first,
    &.last {
      &::after {
        height: 80%;
      }

      .label {
        opacity: 1 !important;
      }
    }

    &.active {
      .pin {
        opacity: 1 !important;
        background-color: #000;

        &::after {
          background-color: #000; 
        }
      }
      
      .label {
        opacity: 1 !important;
      }
    }

    &:hover {
      cursor: pointer;
      z-index: 3;

      .pin {
        opacity: 1;
      }

      .label {
        opacity: 1;
      }

      + .year .label {
        opacity: 0 !important;
      }
    }
  }
}
