.single-nachrichten {
  .article_social {
    padding-bottom: 2em;
  }

  hr {
    margin: 4em 0;
  }

  .kf-front-misc-articles {
    padding-top: 0;
  }

  .wp-caption {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .nachricht-meta {
    text-align: center;
    margin: 1em 0 2em;

    span {
      font-size: 0.75em;

      > span {
        font-size: 1em;
      }
    }
  }

  .cbxwpbkmarkwrap .cbxwpbkmarklistwrap {
    top: 0 !important;
    bottom: auto !important;
  }

  .postcontent {
    position: relative;

    .content-featured {
      padding: 30px 0 0;
    }

    .figure.wp-caption {
      display: flex;
      flex-direction: column;
      align-items: center;

      &::after {
        display: none;
      }

      img.size-full {
        align-self: center;
        max-height: 450px;
        max-width: calc(100% - 60px);

        @media (min-width: 1000px) and (max-width: 1150px){
          max-width: calc(100% - 120px);
        }
      }

      .wp-caption-text {
        max-width: 80%;
        padding: 20px 40px 0 0;
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 10px;
          width: 10px;
          bottom: 6px;
          right: 1em;
          position: absolute;
          background-image: url(../images/icon-lightbox.svg) !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
        }

        @media (min-width: 1200px) {
          max-width: 600px;
        }

        @media (min-width: 1440px) {
          max-width: 750px;
        }

        @media (min-width: 1000px) and (max-width: 1100px) {
          max-width: 500px;
        }
      }
    }

    h1 {
      font-size: 3.75em;
      font-weight: 300;
      font-family: $font-system;
      padding-bottom: 0;
      word-wrap: break-word;
    }

    p {
      font-family: "Helvetica Neue", Arial, sans-serif !important;
      font-size: 1.125em;
      line-height: 1.4em;
      font-weight: 400;
    }

    .static-sticky {
      display: none;
    }

    .floating-sticky {
      margin: 0;
      z-index: 20;
      opacity: 1;
      height: 0 !important;
      transition: 0.3s opacity ease-in-out;
      position: relative;
      background: #fff;

      @media (max-width: 1000px) {
        display: none;
      }

      .floating-inner {
        position: absolute;
        right: 0;
        top: 0;
        width: 200px !important;

        hr.meta-spacer {
          width: 100%;
          margin-bottom: 15px;
          margin-top: 15px;
        }

        > .row {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.is-bottom {
        opacity: 0 !important;
        visibility: hidden;
      }

      &.fixed {
        position: fixed;
        top: 0;
        opacity: 0.2;
        z-index: 1;

        &:hover {
          opacity: 1;
        }

        .floating-inner {
          top: 80px !important;
        }
      }
    }


    .floating-issue {
      position: relative;
      right: 0;
      top: 0 !important;
      width: 200px;
      max-width: 100% !important;

      @media (max-width: 1000px) {
        max-width: 100%;
      }

      a {
        font-size: 1em;
        height: 100%;

        img.fluid {
          max-height: 120px;
        }

        p.head {
          margin: 0;
          font-size: 1em;
          line-height: 1.3em;
          font-weight: 400;
        }

        p.name,
        p.link {
          margin: 0;
          font-size: 1em;
          line-height: 1.3em;
          font-weight: 400;
          margin-bottom: 30px;
        }

        p.link {
          margin-top: 10px;
          text-decoration: underline;
        }

        &:hover {
          p.link {
            text-decoration: none;
          }
        }
      }
    }

    .static-issue {
      .floating-issue {
        border: 1px solid #000;
      }

      img.static-img {
        padding: 30px;
      }
    }

    .linked-post-holder {
      padding-top: 1em;
    }

    .linked-post {
      padding: 4em 0 0;
      margin: 0 auto;
      max-width: 80%;
      width: 650px;

      @media (min-width: 1200px) {
        max-width: 750px;
        width: 750px;
      }

      @media (min-width: 1440px) {
        max-width: 850px;
        width: 850px;
      }

      @media (max-width: 768px) {
        max-width: 90%;
      }

      @media (max-width: 1100px) and (min-width: 1000px) {
        max-width: 90%;
      }

      .hl-text {
        color: $kf-highlight;
        font-size: 1.125em;
        text-align: center;
        margin: 0 0 0.25em 0;
      }

      .highlight-box {
        border: 1px solid #000;
        padding: 3em;
        width: 100%;

        .txt-col {
          @media (max-width: 480px) {
            margin-top: 30px;
          }
        }

        h4 {
          font-size: 2em;
          font-family: $font-headline;
          font-weight: 400;
          line-height: 1.1em;
          margin-bottom: 0;
          text-align: left;
          width: 100%;
        }

        h5 {
          font-size: 1.125em;
          font-weight: 300;
          margin-bottom: 30px;
          text-align: left;
          width: 100%;
          padding: 0;
        }

        img {
          width: 80%;
        }

        .highlight-meta {
          font-size: 0.75em;

          > span {
            font-size: 1em;
          }
        }
      }
    }
  }

  .bottom-sharer {
    margin-top: 5em;
    margin-bottom: 0;
  }
}

.top-anchor {
  @media (max-width: 480px) {
    &.tablet {
      display: none !important;
    }

    &.mobile {
      display: block !important;
    }
  }

  .band-col {
    max-width: 100px;
  }

  .band-link {
    margin-bottom: 0;
  }
}
