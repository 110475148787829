// @media (max-width: 575.99px) {
// }

// @media (min-width: 576px) and (max-width: 767.99px) {
// }

// @media (min-width: 768px) and (max-width: 991.99px) {
// }

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }

  .image-col {
    flex: 0 0 100%;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

// @media (min-width: 992px) and (max-width: 1199.99px) {

// }

// @media (min-width: 1440px) {
// }
