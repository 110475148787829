.anzeige-newsletter {
  position: relative;
  background: $kf-highlight;
  padding: 30px;

  .anzeige-content {
    margin: 0 auto;

    ul {
      max-width: 600px;

      &.vorteile {
        padding: 0;
        margin: 10px 0 20px 0;

        li {
          color: $kf-alternate;
          padding: 0;
          margin: 0;
          font-size: 1em;
          font-weight: 300;
          margin-bottom: 5px;
          list-style: none;
          padding-left: 1.5em;
          position: relative;
          text-align: left;
        }

        li::before {
          content: '✓';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .head {
      color: $kf-contrast;
      font-size: 1.875em;
      font-weight: 300;
      letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0.5em;
    }

    .content {
      color: $kf-contrast;
      font-family: $font-headline;
      font-size: 1.25em;
      line-height: 1.4em;
      text-align: center;
      width: 90%;
      margin: 0 auto 20px auto;

      span {
        font-family: $font-system;
      }
    }

    p {
      margin-top: 30px;
      color: #fff;
      font-size: 0.875em;
      font-weight: 300;
      text-align: left;
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }

    form {
      input[type="submit"] {
        color: $kf-highlight;
        border-color: #fff;
        background-color: #fff;
        margin-top: 0;
        margin-right: 0;
        width: 100%;
      }

      .gfield {
        margin-bottom: 0 !important;
      }

      input[type="text"] {
        color: $kf-contrast;
        border-bottom: 1px solid $kf-contrast;

        &::-webkit-input-placeholder {
          color: $kf-contrast;
        }

        &::-moz-placeholder {
          color: $kf-contrast;
        }

        &:-ms-input-placeholder {
          color: $kf-contrast;
        }
      }

      a {
        margin-top: 1.25em;
        text-transform: uppercase;
      }
    }
  }
}

body.anzeigen {
  .maincontent {
    padding: 0;
  }
}

#gform_wrapper_48 {
  .gform_fields {
    display: block !important;
  }

  .ginput_total_48.strikethrough {
    text-decoration: line-through;
    margin-right: 10px !important;
  }

  @media only screen and (min-width: 641px) {
    .top_label li.gfield.gf_left_half, .top_label li.gfield.gf_right_half {
      width: 50% !important;
    }

    .top_label li.gfield.gf_left_half {
      padding-right: 16px !important;
    }

    li.gfield.onethird {
      width: 33.333% !important;
      padding-right: 10px !important;
    }

    .bootstrap-select > button {
      height: 38px;
    }
  }
}
