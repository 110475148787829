.template-suche,
.template-suche-bilder {
  .kf-bar {
    border-bottom: none !important;
  }

  .content-tabs {
    padding-top: 60px;
  }

  .section-title {
    padding-top: 80px;
    margin-top: 0;
  }

  .result-count {
    padding: 10px 0 20px 0;
    font-size: 1.125em;
    color: $grey_767676;
    font-family: $font-light;
  }

  .suche-lexikon {
    padding-bottom: 30px;

    .toggle-list {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    h1 {
      font-size: 1.55em;
    }

    .kf-front-misc-articles {
      padding-bottom: 30px;
    }

    .personen-lexikon {
      .expand-item {
        margin-bottom: 30px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .kf-mini-item h1 {
      font-family: $font-system;
    }

    .kf-mini-item .row {
      margin-bottom: 0 !important;
    }
  }

  .kf-mini-images-item {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 60px;
    padding-bottom: 80px;
  }

  figure.wp-caption {
    position: relative;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }

    img {
      margin-bottom: 5px;
    }

    .wp-caption-text {
      color: #000;
      font-size: 0.75em;
      font-weight: 300;
      line-height: 1.3em;
      padding: 0 0 60px 0;
      text-align: left;
    }
  }
}
