.navbar-collapse {
  position: fixed;
  height: 0;
  top: 0;
  left: 0;
  z-index: 2147483647;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  overflow-y: auto;
  transition: height 0.5s ease-in;

  .start-link {
    display: flex !important;
  }

  &.show {
    height: 100%;
  }

  &.collapsing {
    transition: height 0.2s ease-out;
  }

  .navbar-toggler {
    position: absolute;
    top: 20px;
    right: 18px;

    @media (max-width: 480px){
      right: 3px;
    }
  }

  .navbar-nav {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    margin: 64px 48px 16px 48px !important;

    @media (max-width: 1000px) {
      justify-content: flex-start;
      margin-left: -5px !important;
    }

    @media (max-width: 768px) {
      margin-left: 0 !important;
    }

    .nav-link {
      padding-right: 0;
      padding-left: 0;
      font-size: 1.5em;
      font-weight: 300;
      margin: 10px 0 0;
      cursor: pointer;
    }
  }

  .nav-item {
    .nav-sub {
      display: none;
      list-style: none;
      padding: 12px 0 0 24px;

      .nav-sub-item {
        .nav-sub-link {
          display: block;
          padding: 0.5rem 0 1rem 0;
          font-size: 1.5em;
          font-weight: 300;
          margin: 10px 0 0;
        }
      }
    }
  }
}

.navbar {
  background-color: transparent !important;
  border: none;
  padding: 0;
  height: 70px;
  display: flex;
  flex-direction: column;
  transition-duration: 0.2s;
  // overflow: hidden;

  .start-link {
    display: flex;
  }

  .main-navi {
    height: 70px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;

    > .container {
      position: relative;
    }
  }

  .login-navi,
  .search-navi {
    background-color: #fff;
    border-bottom: 1px solid $kf-alternate;
    height: 0;
    overflow: hidden;
    display: none;
    transition: opacity 0.3s ease-in-out, overflow 0s ease-in-out 0.3s;

    @media (max-width: 1000px){
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 480px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .login-navi {
    @media (max-width: 768px) {
      .login-container {
        flex-direction: column-reverse !important;
      }

      .login-col {
        border-left: 0 !important;
        margin-bottom: 30px;
      }

      .teaser-col {
        display: none;
      }
    }
  }

  &.show-login {
    height: auto;

    .login-navi {
      height: auto;
      opacity: 1;
      display: block;
    }

    .search-navi {
      height: 0;
      display: none;
      border-bottom: none !important;
    }

    #nav-login-btn {
      border-left: 1px solid #000 !important;
    }
  }


  &.show-search {
    height: auto;

    .login-navi {
      height: 0;
      display: none;
    }

    .search-navi {
      height: auto;
      opacity: 1;
      display: block;
      border-bottom: none !important;
    }

    #nav-login-btn {
      border-left: 1px solid #000 !important;
    }

    img.search-icon-w {
      display: none !important;
    }

    img.search-icon {
      display: block !important;
    }
  }

  &.navbar-scroll-custom:not(.mobile-nav) {
    transform: translateY(-72px);
    transition-duration: 0.6s;
    background-color: rgba(0, 0, 0, 0.1);

    .login-navi,
    .search-navi {
      display: none;
      height: 0;
    }
  }

  &.fixed-top {
    .main-navi {
      background-color: $kf-contrast;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
    }
  }

  &.initial-state {
    transition-duration: 0.3s;

    .main-navi {
      background-color: $kf-contrast;
      border-bottom: 1px solid rgba(242, 242, 242, 0);
    }
  }

  .navbar-nav {
    height: 70px;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 999px){
      margin-left: 30px !important;
      justify-content: flex-start;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .nav-link {
      padding: 0 !important;
      margin: 0 1.25em;
      font-size: 1em;
      margin-top: 5px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;

      @media (min-width: 768px) and (max-width: 839px) {
        padding-right: 10px;
        margin: 0 0.5em;
      }

      @media (min-width: 840px) and (max-width: 1199px) {
        padding-right: 10px;
        margin: 0 0.75em;
      }
    }

    &:first-child {
      .nav-link {
        margin-left: 0;
      }
    }

    .nav-link:hover {
      text-decoration: underline;
    }

    .nav-sub {
      display: none;
      position: absolute;
      top: calc(100% - 10px);
      left: 0;
      z-index: 999999;
      padding: 24px 24px 12px;
      border: 1px solid #ddd;
      margin: 0;
      list-style: none;
      background-color: white;
      width: 270px;

      @media (max-width: 1000px) {
        width: 220px;
      }

      .nav-sub-item {
        margin-bottom: 12px;

        .nav-sub-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.active {
      .nav-link {
        text-decoration: underline;
      }
    }
  }

  .header-add {
    float: right !important;
  }

  .navbar-toggler {
    margin-top: -6px;
  }

  .nav-elem-pos-a {
    position: relative;
  }

  .nav-elem-pos-b {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 20px;
    right: 30px;

    @media (min-width: 1200px) {
      right: 60px;
    }

    @media (min-width: 1600px) {
      right: 60px;
    }

    @media (max-width: 1000px){
      top: 13px;
      right: 30px;
    }

    @media (max-width: 800px) {
      top: 13px;
      right: 30px;
    }

    @media (max-width: 768px) {
      right: 15px;
    }

    @media (max-width: 480px) {
      right: 0;
    }

    #nav-search-btn {
      padding-right: 0.5em;
      margin-top: 3px;
      height: 30px;
      display: inline-block;

      &.toggled {
        text-decoration: underline;
      }
    }

    #nav-login-btn {
      font-weight: 400;
      border-left: 1px solid #000;
      padding-left: 0.5em;
      padding-top: 5px;
      height: 30px;
      display: inline-block;

      &.toggled {
        text-decoration: underline;
      }

      @media (max-width: 480px){
        padding-top: 7px;
      }
    }
  }

  .search-icon-w {
    display: none;
  }

  .search-icon {
    display: block;
  }

  &.is-contrast {
    &.initial-state {
      .nav-link,
      #nav-login-btn {
        color: $kf-contrast !important;
      }

      #nav-search-btn  {
        .search-icon-w {
          display: block;
        }

        .search-icon {
          display: none;
        }
      }

      #nav-login-btn {
        border-left: 1px solid $kf-contrast;
      }

      button[data-target="#navbarSupportedContent"] {
        color: #fff;
      }
    }

    &.navbar-scroll-custom {
      button[data-target="#navbarSupportedContent"] {
        color: #000;
      }
    }
  }

  #desktop-navbar {
    width: 100%;
  }
}

.navbar .nav-item:hover .nav-sub,
.navbar-collapse .nav-item.open .nav-sub {
  display: block;
}

.navbar .nav-item.hasItems .nav-link::after,
.navbar-collapse .nav-item.hasItems .nav-link::after {
  content: '\f107';
  padding-left: 0.5em;
  font-family: FontAwesome, sans-serif;
}

.navbar .nav-item.hasItems:hover .nav-link::after,
.navbar-collapse .nav-item.hasItems.open .nav-link::after {
  content: '\f106';
}

.navbar-collapse#navbarSupportedContent {
  .navbar-nav {
    margin: 64px 48px 16px 48px !important;
  }
}

.single-band,
.template-abonnement,
.single-abo {
  .navbar {
    .main-navi {
      background-color: #fff;
    }

    &.initial-state {
      .main-navi {
        background-color: transparent;
        border-bottom: none;
      }
    }

    &.show-login,
    &.show-search {
      height: auto;

      .main-navi {
        background-color: $kf-contrast !important;
      }
    }

  }
}
