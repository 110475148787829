.template-impressum {
  display: block;

  .wrap {
    background-color: #fff;
  }

  .maincontent {
    h1 {
      font-size: 3.375em;
      margin-bottom: 0.5em;
    }

    h2 {
      font-size: 2em;
      font-family: $font-headline;
      margin-top: 1em;

      span {
        font-family: $font-system;
      }
    }

    h3 {
      font-size: 2em;
    }

    p {
      font-size: 1.125em;
      line-height: 1.4em;
      
      a {
        text-decoration: underline;
      }
    }

    img {
      max-width: 100%;
    }

    padding-bottom: 3em;
  }
}

