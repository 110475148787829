table {
  border-collapse: collapse;
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  height: auto !important;

  @media (max-width: 768px) {
    table-layout: auto;
  }

  @media (min-width: 1000px) {
    width: 130%;
    max-width: 130%;
    margin-left: -15%;
  }

  @media (min-width: 1280px) {
    width: 160%;
    max-width: 160%;
    margin-left: -30%;
  }

}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  border-bottom: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  font-size: 1em;
  padding: 15px 10px 15px 0;
  text-align: left;
  font-weight: 300;

  @media (max-width: 768px) {
    width: auto !important;
    word-break: break-all;
  }
}

table th {
  font-size: 0.75em;
  text-transform: uppercase;
}
