p {
  font-weight: 300;
}


h5.section-title {
  color: $kf-highlight;
  font-size: 1.15em;
  padding-bottom: 2em;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-top: 80px;
  margin-bottom: 40px;
  word-break: inherit !important;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
}

h6 {
  font-weight: 400;
  font-size: 1em;
  word-break: break-word;
}

h3 {
  font-family: $font-headline;
  font-size: 2em;
  font-weight: 300;
  word-break: break-word;
}

h1 {
  font-size: 4em;
  font-family: $font-headline;
  word-break: break-word;

  @media (max-width: 480px){
    font-size: 3.5em;
  }

  & + h3 {
    font-size: 1.56em;
    font-family: $font-system;
    color: #000;
    text-align: left;
    text-transform: none;
    padding-bottom: 0;
    font-weight: 300;
  }
}

h2 {
  font-size: 3.375em;
  font-family: $font-headline;
  font-weight: 300;
  word-break: break-word;

  & + h3 {
    font-size: 1.56em;
    line-height: 1.3em;
    font-family: $font-system;
    color: #000;
    text-align: left;
    text-transform: none;
    padding-bottom: 0;
    font-weight: 300;
  }
}

h4 {
  word-break: break-word;
}

.hiddenText {
  display: none;
}

a.btn-kf-footer_outline:link,
a.btn-kf-footer_outline:visited {
  color: #fff;
}

.btn-kf-footer_outline {
  background-color: $kf-highlight;
  border-radius: 1.2em;
  border-color: #fff;
  font-size: 1em;
  color: #fff;
  text-transform: uppercase;
  padding: 0.8em 1.5em;
}

.kf-home-topics {
  justify-content: center;

  .topics-img {
    padding-bottom: 125%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $grey_9B9B9B;

    @media (max-width: 768px){
      margin-bottom: 30px;
    }
  }

  .topics-img-container {
    position: relative;

    .topics-text {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      display: flex;

      span {
        display: inline-block;
        font-size: 2em;
        font-weight: 300;
        color: #000;
        padding: 0 0.5em 0.5em 0.5em;
      }
    }

    &:hover {
      .topics-text {
        text-decoration: underline;
      }
    }
  }
}

.kf-issue-header {
  background-color: $grey_f5f5f5;
  margin-bottom: 4em;

  .kf-issue-header-content {
    padding: 3em 4em;
  }

  h3 {
    font-family: $font-highlight;
    color: $kf-highlight-light;
    text-shadow: 0 -1px #000, 1px 0 #000, 0 1px #000, -1px 0 #000;
    font-size: 4em;
    margin-bottom: 1em;
    padding: 0;
  }
}

// Quick fix this module
.bootstrap-select.show {
  .dropdown-menu {
    display: block;
  }
}

.kfi-header-text {
  padding-top: 2em;
  // padding-bottom: 5em;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;

  .kfi-header-text-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .img-col {
      width: 180px !important;
      flex: 0 1 auto;
      margin-right: 60px;
      padding-left: 0;
    }

    img {
      width: 180px;
      height: 180px;
    }

    .header-headline {
      padding: 0 !important;

      h3 {
        font-size: 1.56em;
        font-family: $font-system;
        color: #000;
        text-align: left;
        text-transform: none;
        padding-bottom: 0;
        font-weight: 300;
        margin-bottom: 40px;

        @media (max-width: 480px) {
          font-size: 1.2em;
        }
      }
    }

    .header-title {
      font-size: 3.25em;
      line-height: 1.1em;
      margin-bottom: 0;
      padding: 0;

      @media (max-width: 480px) {
        font-size: 2.5em;
      }

      span {
        font-family: $font-system;
        font-weight: 300;
        text-transform: uppercase;
      }
    }

    h4 {
      font-size: 1.7em;
      color: black;
      text-transform: none;
      font-weight: lighter;
      padding-bottom: 0;
    }
  }
}

.kf-top-bar-search-wrapper {
  background-color: $kf-contrast;
  position: relative;
  background-size: cover;
  background-position: center;

  .kf-bar-enclosure {
    padding-top: 70px;
    position: relative;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    .kf-slogan {
      font-size: 1.25em;
      margin-top: 20px;
      color: #ff3e00;
    }

    .kf-bar {
      display: flex;
      align-items: center;
      background-color: transparent;
      border-bottom: 1px solid #f2f2f2;
      width: 100%;

      &.kf-top-bar-search {
        flex: 1;
        position: relative;

        #header-search-form,
        #header-recherche-form {
          width: 100%;
          position: relative;
        }

        #header-search-form {
          padding-top: 2.5% !important;
          padding-bottom: 2.5% !important;
          position: relative;

          @media (max-width: 1440px) {
            padding-top: 2.5% !important;
            padding-bottom: 2.5% !important;
          }

          @media (max-width: 1280px) {
            padding-top: 2% !important;
            padding-bottom: 2% !important;
          }

          @media (max-width: 800px) {
            padding-top: 1.5% !important;
            padding-bottom: 1.5% !important;
          }
        }

        &.research {
          position: absolute;
          top: 70px;
          left: 0;
          transform: translateY(-200%);
          padding-top: 2.5% !important;
          padding-bottom: 2.5% !important;
          background-color: $kf-contrast;
          width: 100%;
          height: auto !important;

          @media (max-width: 1440px) {
            padding-top: 2.5% !important;
            padding-bottom: 2.5% !important;
          }

          @media (max-width: 1280px){
            padding-top: 2% !important;
            padding-bottom: 2% !important;
          }

          @media (max-width: 800px) {
            padding-top: 1.5% !important;
            padding-bottom: 1.5% !important;
          }

          &.show {
            transform: translateY(0%);
            transition: 1s transform ease-in-out;
          }
        }

        input[type='text'] {
          border-radius: 30px;
          width: 100%;
          padding: 5px 4em 0 1em !important;
          height: 80px !important;
          border: 1px solid $grey_f5f5f5;
          background-color: $grey_f5f5f5;
          color: #000;
          font-size: 1.5em !important;
          font-weight: 300 !important;

          @media (max-width: 1000px) {
            height: 60px !important;
          }
        }

        #search-submit-query {
          top: 45px;

          @media (max-width: 768px) {
            top: 18px;
          }
        }

        #research-submit-query {
          top: 22px;

          @media (max-width: 768px){
            top: 12px;
          }
        }

        input[type='image'] {
          position: absolute;
          right: 2em;
          margin: 0 auto;
          height: 32px;
          width: 32px;

          @media (max-width: 1000px) {
            top: 12px;
            right: 20px;
          }
        }
      }
    }

    .kf-bar:hover {
      cursor:pointer;
    }
  }
}

.kfi-search-research-results-wrapper {
  padding: 4em;

  .kfi-search-research-results-rows-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;

    .kfi-search-research-results {
      display: flex;

      h3 {
        text-align: left;
        margin: 0;
        padding: 0;
      }

      div {
        position: relative;
        width: 100%;
        min-height: 1px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 4em;

        span {
          display: block;
          font-size: 1.92308em;
          color: #000;
          text-transform: uppercase;
        }
      }
    }
  }
}


.image-col {
  flex: 0 0 50%;
  padding: 15px;
}

.tab-content {
  .all {
    padding-bottom: 5em;
  }
}


.bootstrap-select {
  &.sm {
    width: 160px;
  }

  &.md {
    width: 320px;
  }

  > button {
    border: 1px solid $grey_9B9B9B;
    border-radius: 15px;
    padding-top: 9px;
    height: 40px;
  }
}

.teaser-medium {
  margin-bottom: 4em;
}

.single-person {
  .kfi-header-text {
    .kfi-header-text-content {
      h1 + h3 {
        margin-top: 10px !important;
        font-size: 1.125em !important;
        font-family: $font-headline !important;
      }
    }
  }
}

.kfi-leaky-home {
  &.wide_newsletter_element {
    text-align: left;
  }

  .nl_element_title {
    line-height: 30px;
  }
}
