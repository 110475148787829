.inst-artikel,
.grossauss-artikel {
  .hr {
    margin-bottom: 60px;
    
    &:first-of-type {
      display: none;
    }
  }
}

.inst-artikel {
  h5.section-title {
    margin-top: 0 !important;
  }

  .toggle-more {
    margin-bottom: 30px;
  }

  .inst-col {
    padding-bottom: 0 !important;

    .row {
      margin-bottom: 0 !important;
    }

    h1 {
      font-size: 1.55em;
      font-family: $font-system;
    }

    &:hover {
      text-decoration: underline;
    }
  }

}

.grossauss-artikel {
  h5.section-title {
    margin-top: 0 !important;
  }

  .toggle-more {
    margin-bottom: 30px;
  }

  .grossauss-col {
    padding-bottom: 0 !important;

    .row {
      margin-bottom: 0 !important;
    }

    h1 {
      font-size: 1.55em;
      font-family: $font-system;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.person_search_results {
  .letter-row {
    padding-bottom: 30px !important;
    margin-bottom: 0 !important;

    .person-col {
      padding-bottom: 0;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 60px;
    width: 100%;
    margin-right: 30px;
    margin-left: 30px;
  }
}
