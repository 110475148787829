.is-contrast {
  .kf-slogan,
  .kf-stage-extras {
    color: $kf-contrast !important;
  }
}

.kf-stage {
  min-height: 70vh;
  padding: 0;

  @media (max-width: 640px) {
    min-height: 100vh;
  }

  .withbg {
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .kf-stage-extras {
    color: #000;
    margin-top: 250px;
    padding-bottom: 4em;
    display: flex;
    flex-direction: row;

    @media (max-width: 640px) {
      margin-top: 150px;
    }

    @media (max-width: 640px) {
      padding-bottom: 30px;
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }

    > div {
      align-self: flex-end;
      justify-content: flex-end;

      &:first-of-type {
        width: 50%;
        flex: 0 1 50%;

        @media (max-width: 640px) {
          width: 100%;
          flex: 0 1 100%;
        }
      }

      &:last-of-type {
        width: 50%;
        flex: 0 1 50%;

        @media (max-width: 640px) {
          width: 100%;
          flex: 0 1 100%;
        }
      }
    }

    .kf-stage-search {
      position: relative;

      input[type='text'] {
        background: #fff;
        border-radius: 14px;
        width: 100% !important;
        padding: 3px 3em 0 1em !important;
        border: 0;
        font-size: 1.125em !important;
        height: 50px !important;
        line-height: 50px !important;
      }

      input[type='image'] {
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }

    .kf-stage-claim {
      font-size: 2em;
      font-weight: 200;
      margin-bottom: 1em;
      line-height: 1.1em;
      display: block;
    }

    .kf-stage-annotation {
      display: block;
      font-size: 0.75em;
      text-align: right;
      position: absolute;
      right: 4em;
      bottom: 4em;
      width: 30%;
      max-width: 300px;
      margin-top: 10px;

      @media (max-width: 1200px) {
        right: 30px;
        bottom: 30px;
      }

      @media (max-width: 640px){
        display: block;
        font-size: 0.75em;
        text-align: left;
        position: relative;
        right: 0;
        left: 0;
        bottom: 0;
        max-width: 100% !important;
        width: auto;
        margin-top: 10px;
      }
    }
  }

  .kf-bar-enclosure {
    padding-top: 70px;

    .kf-bar {
      width: 100%;
      height: 0;
      padding-bottom: 12.5%;
      background-color: $kf-highlight;
      position: relative;

      .logo-50-years {
        img {
          height: 100%;
          width: auto;
          padding: 0.75rem;
          position: absolute;
          right: 0;
        }
      }

      @media (max-width: 768px){
        padding-bottom: 100px;
      }
    }

    .kf-slogan {
      font-size: 1.25em;
      margin-top: 20px;
      color: $kf-highlight !important;
    }
  }
}
