body {
  background-color: #f4f4f4;
  font-size: 16px;
  line-height: 1.4em;
  font-family: $font-system;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  .hide-arrows {
    position: relative;
    z-index: 999;
  }

  .bg-white {
    background-color: #fff;
  }

  .deselect {
    display: none !important;
  }

  .cookie-notice {
    .cookie-notice-container {
      padding: 30px;
      max-width: 800px;
      margin: 0 auto;
    }

    .cn-notice-text {
      display: block;
      margin-bottom: 10px;
    }
  }

  &.admin-bar {
    .navbar {
      top: 32px !important;
    }

    .timeline-sticky {
      &.fixed {
        position: fixed;
        top: 32px !important;
        z-index: 99;
      }

      &.timeline-scroll-custom {
        top: 102px !important;
      }
    }
  }

  .hr {
    border-top: 1px solid $grey_ddd;
  }

  p {
    line-height: 1.4em;
  }

  strong {
    font-weight: 500;
  }

  > .wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  .maincontent {
    background-color: $kf-contrast;

    &.static-page {
      padding: 60px calc((100% - 770px ) / 2) 120px;

      .gform_wrapper {
        margin: 30px 0 60px;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;
    margin-left: -30px;

    @media (max-width: 768px) {
      margin-right: -15px !important;
      margin-left: -15px !important;
    }

    @media (max-width: 480px) {
      margin-right: -10px !important;
      margin-left: -10px !important;
    }
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto,
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto,
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto,
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto,
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 30px;
    padding-left: 30px;

    @media (max-width: 768px) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .container {
    margin: 0 auto;
    padding-left: 60px;
    padding-right: 60px;

    @media (min-width: 1200px) {
      max-width: calc(100% - 60px);
    }

    @media (min-width: 1600px) {
      max-width: 1440px;
    }

    @media (max-width: 1200px){
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (max-width: 768px){
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (max-width: 480px) {
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

  }

  .container-inner {
    padding-left: 60px;
    padding-right: 60px;

    @media (max-width: 768px){
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (max-width: 480px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &.single-artikel {
    .maincontent {
      margin-top: 0;

      > div:not(.bottom-trigger) {
        padding-bottom: 6em;
      }
    }
  }

  hr {
    &.full-width {
      margin-left: -4em;
      margin-right: -4em;
    }
  }

  .kf-front-misc-articles {
    padding: 0;

    .text-col > a {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
    }
  }

  .kf-front-current-issue {
    padding: 0;

    @media (max-width: 768px) {
      .img-col {
        margin-top: 30px;
      }
    }
  }

  .row.kf-single-item {
    margin-bottom: 60px;
  }

  .full-width {
    position: relative;

    &.hidden {
      display: none;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      left: calc((100vw - 100%) / 2 * -1);
      right: 0;
      height: 100%;
      top: 0;
      z-index: -1;
      width: 100vw;
    }

    &.alternate {
      &::after {
        background-color: $kf-alternate;
      }
    }
  }

  .top-anchor {
    background-color: #f2f2f2;
    padding: 10px 0;
    margin-bottom: 30px;
    display: flex;
    position: relative;
    z-index: 999;

    @media (max-width: 480px) {
      display: none;
    }

    @media (min-width: 1000px) {
      display: none !important;
    }

    .band-col {
      .floating-issue {
        max-width: 80px;
      }
    }

    .meta-col {
      p,
      .article_rubriken,
      .article_meta {
        font-size: 1em;
        font-weight: 300;
        padding: 0;
        text-align: left;
      }

      .band-link {
        span {
          text-transform: uppercase;
        }
      }
    }
  }

  .social-mobile {
    @media (min-width: 1000px) {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;
    }

    .cbxwpbkmarkwrap .cbxwpbkmarklistwrap {
      top: 0 !important;
      bottom: auto !important;
    }
  }

  .teaser-mini,
  .teaser-medium,
  .teaser-large,
  .teaser-hover,
  .story,
  .nachricht {
    &:hover {
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        text-decoration: underline
      }
    }
  }

  .kundennummerkonvertierungform {
    .login-additonal-text {
      margin-bottom: 10px;

      p {
        margin-bottom: 5px;

        > * {
          text-decoration: underline !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .table-holder {
    overflow-x :scroll;

    table {
      min-width: 600px;
    }
  }
}

.align-bottom {
  display: flex;
  align-items: flex-end;
}
