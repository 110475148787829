.leaky_paywall_message_wrap {
  max-width: 80%;
  width: calc(650px + 8em);
  margin: -8em auto 2em auto;
  padding-top: 8em;

  @media (min-width: 1200px) {
    max-width: 750px;
    width: 750px;
  }

  @media (min-width: 1440px) {
    max-width: 850px;
    width: 850px;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  @media (max-width: 1100px) and (min-width: 1000px) {
    max-width: 90%;
  }

  #leaky_paywall_message {
    background-color: $grey_f2;
    box-shadow: 0 0 0.5em 0.25em #fff;
    border: 10px solid $kf-highlight;
    border-radius: 0;
    padding: 60px;

    @media (max-width: 768px) {
      padding: 30px;

    }

    @media (max-width: 480px) {
      padding: 30px;
    }

    #leaky_paywall_inner {
      display: block;

      h4 {
        font-family: $font-headline;
        font-size: 2em;
        margin-bottom: 30px;
        text-align: left;
        max-width: 100%;
        padding-bottom: 0 !important;
      }

      .expand-login-text {
        text-decoration: underline;
        cursor: pointer;

        &::before {
          content: '';
          display: inline-block;
          height: 15px;
          width: 15px;
          background-image: url('../images/icon_carousel_arrow_right.svg');
          transform: rotate(90deg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 12px 12px;
          margin-right: 10px;
        }

        &.show {
          &::before {
            transform: rotate(270deg);
          }
        }
      }

      .expand-login-holder {
        display: none;
        max-width: 400px;
        width: 100%;
        padding-top: 10px;

        .login-additonal-text {
          font-size: 0.875em;
        }
      }

      p {
        font-family: $font-system;
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 1.125em;

        a {
          font-weight: inherit;
          text-decoration: underline;
        }
      }

      ul {
        margin: 0.5em 0 1.5em;
        padding-left: 2em;

        li {
          font-weight: 300;
          font-size: 1.125em;

          p {
            margin: 0;
          }
        }
      }

      hr {
        margin: 2rem 0;
      }

      .btn-link {
        font-size: 1em;

        a.kf-btn {
          font-weight: 300;
          color: #fff !important;
          text-decoration: none;
        }
      }

      #wp-submit {
        margin-top: 20px;
      }

      a.inline-link {
        text-decoration: underline;
        font-size: 0.875em;
        font-weight: 300;
        margin-top: 0.5em;

        + p {
          font-size: 0.875em;
          font-weight: 300;
          margin-top: 0.5em;
        }
      }

      .gform_wrapper {
        max-width: 100%;
        margin-left: 0;
      }

      form {
        padding-top: 1em;
        margin-left: 0 !important;

        input[type="text"],
        input[type="password"],
        input[type="email"] {
          font-size: 0.75em !important;
          line-height: 30px !important;
          height: 30px !important;
        }

        .gform_fields {
          padding-left: 0;

          .gfield {
            margin-bottom: 0 !important;
          }
        }

        .kf-btn-alt {
          font-weight: 300;
        }

        .inline-link {
          display: block;
        }

        input#user_login {
          margin-top: 1em;
          margin-bottom: 20px !important;
        }

        input#user_pass {
          margin-bottom: 5px !important;
        }

        input[name="password"] {
          margin-bottom: 0;
        }
      }
    }
  }
}

.artikel-countdown {
  position: fixed;
  left: -320px;
  bottom: 20px;
  width: 250px;
  background: $kf-contrast;
  border: 1px solid $kf-highlight;
  padding: 20px;
  z-index: 99;
  transition: left 0.8s ease-in-out;

  &.show {
    left: 20px;
  }

  .ac-m {
    font-size: 1em;
    color: #000;
    padding: 0;
    margin: 0;

    .ac-l {
      color: $kf-highlight;
    }
  }
}

.artikel-countdown:hover {
  cursor: pointer;
}

body ul#gform_fields_33 .ginput_right, body.registrieren ul#gform_fields_32 .ginput_right {
  display: none;
}
