.kf-front-title-articles {
  > h3 {
    text-align: center;
  }

  img {
    width: 100%;
  }

  h4 {
    font-family: $font-headline;
    font-size: 2.3em;
    padding: 1em 0 0.2em 0;
    margin: 0;
    text-align: left !important;
    width: 100% !important;

    @media (max-width: 800px) {
      font-size: 1.875em !important;
      line-height: 1em;
      padding: 0.75em 0 0.25em 0;
      margin: 0;
    }
  }

  h5:not(.section-title) {
    font-size: 1.125em;
    font-weight: 300;
    padding-bottom: 0;

    @media (max-width: 800px) {
      color: black;
      text-transform: none;
      text-align: left;
      font-size: 1.125em !important;
      font-family: $font-system;
      line-height: 1.25em;
      font-weight: 300;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .artikel-meta {
    font-size: 0.75em;
    font-weight: 400;
    margin-top: 20px;

    div {
      display: inline-block;
    }

    span {
      font-size: 1em !important;
    }
  }

  .carousel {
    padding-bottom: 4em;

    .slick-track {
      display: flex;
    }

    .carousel-control-next,
    .carousel-control-prev {
      margin-top: 0;
    }

    .carousel-caption {
      position: relative;
      display: block;
      right: 0;
      bottom: -35px;
      left: 0;
      padding: 0;
      margin: 0 auto;
      width: 100px;
      text-align: center;
      font-size: 1.25em;

      @media (max-width: 480px) {
        bottom: -25px;
      }

      h5 {
        color: black;
        font-family: $font-headline;
        padding-bottom: 0;
        text-align: center;
      }
    }
  }
}

.kf-front-abo-teaser {
  background-color: $kf-alternate;
}

.kf-front-current-issue {
  .button-holder  {
    margin-top: 2.5em;
  }

  p {
    font-family: $font-headline;
    font-size: 1.25em;
    line-height: 1.4em;
    margin-top: 15px;
    margin-bottom: 0;
  }

  h1 {
    line-height: 1.1em;
    margin: 0;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.875em;
    line-height: 1.1em;
    margin-bottom: 0;
    margin-top: 15px;
  }

  .kf-btn {
    margin-right: 10px;
  }

  img {
    margin: 30px 0 auto;
    max-width: 100%;
    max-height: 500px;
    align-self: center;
  }

  a.toggleText {
    font-family: $font-system;
    font-size: 0.75em;
    text-transform: uppercase;
    text-decoration: underline;
    color: $grey_9B9B9B;
  }

  a.hideText {
    color: $grey_9B9B9B;
    font-size: 0.75em;
    font-family: $font-system;
    text-decoration: underline;
  }

  a.toggleText:visited,
  a.toggleText:active,
  a.toggleText:hover {
    text-decoration: none;
  }

  .elipsis  {
    display: inline;
  }

  .col-sm-6 {
    padding: 25px 0;
  }

  .img-col {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  @media screen and (max-width: 700px) {
    .row {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .img-col {
      min-height: 250px;
    }
  }
}


.kf-front-misc-articles {
  .item {
    margin-bottom: 6em;
  }

  > h3 {
    text-align: center;
  }

  img {
    width: 100%;
  }

  h1 {
    font-size: 1.875em;
    line-height: 1em;
    padding: 0.75em 0 0.25em 0;
    margin: 0;
  }

  h3.more_articles_teaser {
    color: black;
    text-transform: none;
    text-align: left;
    font-size: 1.125em;
    font-family: $font-system;
    line-height: 1.25em;
    font-weight: 300;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .more_articles_hits {
    font-size: 0.875em;
    font-weight: 300;
    color: #9b9b9b;
  }

  .artikel-meta {
    font-size: 0.75em;
    font-weight: 300;
    margin-top: 20px;

    div {
      display: inline-block;
    }

    span {
      font-size: 1em !important;
    }
  }

  > .row {
    padding-bottom: 5em;
  }

  .kf-single-item {
    h1 {
      font-size: 4em;
      line-height: 1.1em;
      padding: 0 0 0.25em 0;

      @media (max-width: 800px) {
        font-size: 1.875em !important;
        line-height: 1em;
        margin: 0;
      }

      @media (max-width: 480px) {
        padding: 0.75em 0 0.25em 0;
      }

    }

    h3 {
      color: black;
      text-transform: none;
      text-align: left;
      font-size: 1.875em;
      line-height: 1.3em;
      font-weight: 300;
      padding-bottom: 0;

      @media (max-width: 800px) {
        color: black;
        text-transform: none;
        text-align: left;
        font-size: 1.125em !important;
        font-family: $font-system;
        line-height: 1.25em;
        font-weight: 300;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
    }

    @media (max-width: 800px) {
      .artikel-meta {
        margin-top: 20px !important;
      }
    }
  }

  .kf-mini-item {
    img {
      width: 100%;
    }

    h1 {
      font-size: 1.875em;
      font-family: $font-headline;
      line-height: 1.1em;
      font-weight: 200;
      padding-top: 0;
      margin: 0;

      @media (max-width: 800px) {
        font-size: 1.875em !important;
        line-height: 1em;
        margin: 0;
      }

      @media (max-width: 1000px) {
        padding: 0.75em 0 0.25em 0;
      }
    }

    h3 {
      color: black;
      text-transform: none;
      text-align: left;
      font-family: $font-system;
      font-size: 1.125em;
      font-weight: 300;
      margin-bottom: 5px;

      @media (max-width: 800px) {
        color: black;
        text-transform: none;
        text-align: left;
        font-size: 1.125em !important;
        font-family: $font-system;
        line-height: 1.25em;
        font-weight: 300;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &:last-of-type {
        padding-bottom: 1em;
      }
    }

    span {
      font-size: 0.75em;
      font-weight: 400;

      strong {
        font-weight: 500;
      }
    }

    .row {
      margin-bottom: 4em;
    }
  }
}

.kf-input {
  position: relative;

  input:focus {
    outline: none;
  }

  label {
    color: $kf-contrast;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 2px;
    transition: 0.2s ease all;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: -18px;
    font-size: 0.7rem;
  }
}

.maincontent_home {
  margin-top: 0 !important;
}

.template-startseite {
  h5.section-title {
    margin-top: 100px;

    @media (max-width: 800px) {
      margin-top: 60px;
    }

    @media (max-width: 480px) {
      margin-top: 60px;
    }
  }

  .navbar {
    .main-navi {
      width: 100%;
      background-color: $kf-contrast;
    }

    &.initial-state {
      .main-navi {
        background-color: transparent !important;
        border-bottom: none;
      }

      .search-icon-w {
        display: none;
      }

      .search-icon {
        display: block;
      }
    }

    &.show-login,
    &.show-search {
      .main-navi {
        background-color: $kf-contrast !important;
      }

      .nav-link,
      #nav-login-btn,
      #nav-search-btn {
        color: #000 !important;
      }
    }
  }

}
