a.activation-link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.grecaptcha-badge {
  z-index: 999 !important;
}

.single-abo {
  .kf-article-stage .kf-bar-enclosure {
    padding: 0 !important;
  }

  .abos-overview {
    @media (max-width: 800px) {
      padding-bottom: 0;
    }
  }

  .abo-card {
    background-color: $kf-alternate;
    padding: 2em;
    margin-top: 60px;
    margin-bottom: 120px;

    @media (max-width: 800px) {
      margin-bottom: 0;
    }

    hr {
      border-top-color: $grey_ddd;
      margin: 1.5rem 0;
    }

    .title-col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    p {
      margin: 0;

      &.abo-preis {
        text-align: right;
        font-size: 2em;
        font-weight: 300;
        line-height: 1em;
        flex-shrink: 0;
      }

      &.hinweise  {
        font-family: 'HelveticaNeueLT', 'Helvetica Neue', Arial, sans-serif !important;
        font-size: 0.875em;
      }
    }

    h3.abo-titel {
      text-align: left;
      color: #000;
      font-size: 1.875em;
      font-family: $font-headline;
      line-height: 1.3em;
      margin: 0;
      padding: 0;
    }

    a.kf-btn {
      margin: 6em auto 2em auto;
      display: table;
    }

    ul {
      margin: 0;
      padding: 0.5em 0 0 0;

      li {
        padding: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 300;
        margin-bottom: 1em;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }
}

.bestell-form {
  padding-top: 60px;

  .gform_heading {
    display: none;
  }

  .code-valid {
    &::after {
      color: $kf-highlight;
      content: '\2713 Gutschein angenommen';
      display: block;
      position: absolute;
      top: 8px;
      right: 0;
      font-weight: 300;
      font-size: 0.875em;
    }
  }

  input {
    margin-bottom: 0 !important;
  }

  li.gfield_html_formatted small {
    margin-top: 20px;
    font-family: HelveticaNeueLT, Helvetica Neue, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 12px;
    font-weight: 300;
  }

  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;

    &.half {
      max-width: calc(50% - 8px) !important;
    }

    &.onethird {
      max-width: calc(34% - 8px) !important;
    }

    &.twothird {
      max-width: calc(66% - 8px) !important;
    }
  }

  .gfield_description.validation_message {
    font-size: 14px !important;
  }

  .gform_footer {
    display: none;
  }

  .gform_wrapper {
    @media (max-width: 640px) {
      max-width: 100%;
    }
  }

  .gform_fields {
    justify-content: space-between !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start;

    @media (max-width: 640px) {
      flex-direction: column !important;
    }

    #field_13_55 {
      display: none;
    }

    .gfield {
      margin-bottom: 20px !important;
    }

    .column-left {
      flex: 0 1 auto;
      width: calc(50% - 30px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      @media (max-width: 640px) {
        width: 100%;
        flex: 0 1 auto;
      }

      .half.gfield_visibility_visible {
        max-width: calc(50% - 8px) !important;
        width: calc(50% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 50%;
      }

      .half.gfield_visibility_visible + .half.gfield_visibility_visible {
        margin-left: 16px !important;
      }

      .onethird.gfield_visibility_visible {
        max-width: calc(34% - 8px) !important;
        width: calc(34% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 34%;
      }

      .twothird.gfield_visibility_visible {
        max-width: calc(66% - 8px) !important;
        width: calc(66% - 8px) !important;
        display: inline-block !important;
        flex: 0 1 66%;
      }

      .twothird.gfield_visibility_visible + .onethird {
        margin-left: 16px !important;
      }

      .onethird.gfield_visibility_visible + .twothird {
        margin-left: 16px !important;
      }
    }

    .column-right {
      flex: 0 1 auto;
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;

      @media (max-width: 640px) {
        width: 100%;
        flex: 0 1 auto;
      }
    }

    .gfield_checkbox {
      input {
        margin-top: -5px !important;
      }

      label {
        font-size: 1.125em !important;
        font-weight: 300 !important;
        margin-left: 15px;
      }
    }

    .gfield_select {
      width: 100% !important;
      max-width: 100%;

      .gfield_select {
        display: none !important;
      }

      .dropdown-toggle {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-left: 0 !important;
        font-weight: 300 !important;
        margin-top: 2px !important;
      }
    }

    .gfield.column-1 {
      break-inside: avoid-column;
    }

    .gfield.column-2 {
      break-inside: avoid-column;
    }

    .gfield_html {
      font-family: $font-headline;
      font-size: 1.25em;
      margin-bottom: 1em;
    }

    .gfield_radio {
      input {
        margin-top: -6px !important;
      }

      label {
        font-size: 1.125em;
        font-weight: 300 !important;
        margin-left: 10px;
      }
    }
  }

  .dropdown-menu {
    transform: none !important;
  }

  .dropdown-menu.open.show {
    max-height: 370px !important;
    margin-top: 11% !important;
  }

  ul.dropdown-menu.inner {
    max-height: 360px !important;
  }

  ul.dropdown-menu.inner:last-child {
    padding-bottom: 10px;
  }
}

