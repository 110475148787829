.kfi-header-abo {
  background-color: $kf-alternate;
  padding: 2em 0 0 0;

  .kfi-header-abo-content {
    img {
      max-height: 400px;
      max-width: 100%;
      align-self: center;

      @media (max-width: 800px) {
        margin-bottom: 30px;
      }
    }

    h1 {
      font-size: 3.375em;
      padding: 0;

      @media (max-width: 480px) {
        font-size: 2.5em;
      }

      span {
        font-family: $font-system;
        font-weight: 300;

        @media (max-width: 480px) {
          font-size: 0.9em;
        }
      }
    }

    h4 {
      font-size: 1.7em;
      color: black;
      text-transform: none;
      font-weight: lighter;
      padding-bottom: 0;
    }

    p {
      font-family: 'HelveticaNeueLT', 'Helvetica Neue', Arial, sans-serif !important;
      font-size: 1.5em;
      font-weight: 300;
      margin-bottom: 1em;
    }

    ul {
      padding: 0;
      margin: 2em 0 3em 0;

      li {
        padding: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 300;
        margin-bottom: 15px;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.abos-overview {
  display: block;
  padding-top: 4em;

  .abo-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .abo-teaser {
      background-color: #fff;
      border: 1px solid $grey_ddd;
      flex: 0 1 30%;
      width: 30%;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      margin-right: 5%;

      &.abo-highlight {
        border: 3px solid $kf-highlight;
      }

      .alternative-headline {
        font-size: 1.125em;
        font-weight: 300;
        text-align: center;
        margin-bottom: 2em !important;
      }

      @media (max-width: 800px) {
        flex: 0 1 47.4%;
        width: 47.5%;

        &:nth-child(2n) {
          margin-right: 0%;
        }
      }

      @media (max-width: 480px) {
        flex: 0 1 100%;
        width: 100%;
        margin-right: 0% !important;
      }

      @media (min-width: 800px) {
        &:nth-child(3n) {
          margin-right: 0%;
        }
      }

      .abo-highlight-text {
        margin-top: -46px;
        text-align: center;
        background: $kf-highlight;
        padding-bottom: 10px;
        padding-top: 14px;
        margin-left: -3px;
        width: calc(100% + 6px);
        color: white;
        text-transform: uppercase;
      }

      .top {
        background-color: $kf-alternate;
        display: flex;
        flex-direction: column;
      }

      h3 {
        font-size: 2em;
        color: #000;
        font-family: $font-headline;
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
        padding: 45px 0 0 0;
        margin-bottom: 0;
      }

      p {
        font-size: 1.125em;
        font-weight: 300;
        text-align: center;
        margin: 0 0 2em 0;
      }

      img {
        align-self: center;
        margin-bottom: 2em;
        height: 200px;
        width: auto;
      }

      a.kf-btn {
        align-self: center;
        margin-bottom: 2em;
      }

      ul {
        margin: 0;
        padding: 30px;
        background-color: #fff;

        li {
          padding: 0;
          margin: 0;
          font-size: 1.125em;
          font-weight: 300;
          margin-bottom: 1em;
          list-style: none;
          padding-left: 1.5em;
          position: relative;
        }

        li::before {
          content: '✓';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .abo-alternative-features {
      ul {
        padding: 0 30px;
      }

      p {
        margin: 0 0 1.5em 0;
      }

      p:first-child {
        margin-top: 2em;
      }

    }
  }
}

.abos-hilfe {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
  text-align: center;

  h2 {
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 2em;
  }

  .block {
    font-family: HelveticaNeueLT, Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    line-height: 3em;
    font-weight: 300;
  }

  .col-lg-4 {
    &:first-child,
    &:nth-child(2) {
      border-right: 2px solid #fff;
    }

    #abos-hilfe-phone {
      font-size: 20px !important;
    }
  }

  .kf-btn-alt {
    border-radius: 0;
    background-color: white;
  }

  a {
    font-family: HelveticaNeueLT, Helvetica Neue, Arial, sans-serif;
  }


  @media (max-width: 480px) {
    .col-lg-4:first-child,
    .col-lg-4:nth-child(2) {
      border-bottom: 2px solid #fff;
      padding-bottom: 20px;
    }

    h2 {
      margin-bottom: 15px
    }
  }
}

.abos-cross {
  margin-top: 60px !important;
  margin-bottom: 60px !important;

  h2 {
    font-size: 2em;
    color: #000;
    font-family: "SabonNextLTPro-Regular", serif;
    margin: 0 auto 25px;
  }

  .cross-img {
    max-width: 200px;
  }

  .col-lg-6:first-child {
    border-right: 2px solid #fff;
  }

  a {
    text-transform: uppercase;
    text-decoration: underline;
  }

  .mb-4 {
    font-size: 1.125em;
    font-weight: 300;
  }

  @media (max-width: 480px) {
    .pt-4 {
      padding: 15px;
    }

    .col-lg-6:first-child {
      border-bottom: 2px solid #fff;
      border-right: 0;
    }

    .d-flex {
      flex-direction: column;
      text-align: center;
    }


    & {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
  }
}

.abos-testimonials {
  h2 {
    font-size: 2em;
    color: #000;
    font-family: SabonNextLTPro-Regular, sans-serif;
    margin: 0 auto 25px;
  }

  .col-md-4 {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }

  .col-lg-10 {
    margin-left: 37px;
  }

  .blockquote-content {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1em;
    list-style: none;
  }

  .row {
    padding-top: 10px;
  }

  .row:first-child {
    margin-left: -24px;
  }

  .blockquote-footer {
    color: #000;
  }

  .blockquote {
    p::before {
      content: open-quote;
      font-family: $font-headline;
      color: $kf-highlight;
      font-size: 4em;
      position: absolute;
      left: 0;
    }

    p::after {
      content: close-quote;
      visibility: hidden;
    }
  }

  @media (max-width: 480px) {
    .blockquote {
      margin-bottom: 2em;
    }

    .col-lg-10 {
      margin-left: 0;
    }

    .blockquote-content {
      max-width: 96%;
      margin-left: 20px;
    }
  }
}

