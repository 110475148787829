.kfi-header-band {
  background-color: $kf-alternate;
  padding: 2em 0 0 0;

  .kfi-header-band-content {
    .bandnummer {
      font-size: 3em;
      margin-bottom: 30px;
      font-family: $font-highlight;
      color: $kf-contrast;
      text-shadow: 0 -1px #000, 1px 0 #000, 0 1px #000, -1px 0 #000;
    }

    img {
      height: 400px;
      max-width: 100%;
      align-self: center;
      margin-bottom: 30px;
    }

    h1 {
      font-size: 3.5em;
      padding: 0;
      margin-bottom: 0;

      span {
        font-family: $font-system;
        font-weight: 300;
      }
    }

    h3 {
      font-size: 1.875em;
      line-height: 1.1em;
      margin-bottom: 15px
    }

    h4 {
      font-size: 1.7em;
      color: black;
      text-transform: none;
      font-weight: lighter;
      padding-bottom: 0;
    }

    p {
      font-size: 1.25em;
      font-family: $font-headline;
      font-weight: 300;
      margin-bottom: 1em;

      .elipsis  {
        display: inline;
      }

      .toggleText {
        color: $grey_9B9B9B;
        font-size: 0.75em;
        font-family: $font-system;
        text-decoration: underline;
      }

      .hideText {
        color: $grey_9B9B9B;
        font-size: 0.75em;
        font-family: $font-system;
        text-decoration: underline;
      }
    }

    a.kf-btn, a.kf-btn-alt {
      margin-bottom: 60px;
    }

    ul {
      padding: 0;
      margin: 2em 0 3em 0;

      li {
        padding: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 300;
        margin-bottom: 1em;
        list-style: none;
        padding-left: 1.5em;
        position: relative;
      }

      li::before {
        content: '✓';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

body.single-band {
  .text-col-hl {
    color: $kf-highlight;
    padding: 0;
    text-align: center;
    font-size: 1em;
    font-weight: 300;
    margin: 0 auto;
  }

  h5.section-title {
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    border-bottom: 1px solid $kf-alternate;
    height: 6em;
  }
}

.maintitle h2 {
  font-family: $font-system;
  font-size: 1.875em;
  line-height: 1.1em;
  margin-bottom: 0;
}

.band-navigation {
  border-bottom: 1px solid $kf-alternate;
  height: 6em;

  .prev-col,
  .next-col {
    .band-link {
      display: flex;
      height: 6em;

      .bandnummer {
        font-size: 1.875em;
        margin-top: 2px;
        font-family: $font-highlight;
        color: #fff;
        text-shadow: 0 -1px #000, 1px 0 #000, 0 1px #000, -1px 0 #000;
      }
    }
  }

  .prev-col {
    padding-left: 2em;

    .band-link {
      justify-content: flex-start;
      align-items: center;

      img {
        margin-right: 1em;
      }
    }
  }

  .next-col {
    padding-right: 2em;

    .band-link {
      justify-content: flex-end;
      align-items: center;

      img {
        margin-left: 1em;
      }
    }
  }

  .text-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .person_search_results {
    padding: 3em 0;

    .person {
      margin-bottom: 0.5em;
    }
  }
}

.kf-categories-repeater {
  background-color: $kf-alternate;
  padding-top: 3.5em;
  padding-bottom: 1em;

  @media (max-width: 480px) {
    padding-bottom: 2em;
    padding-top: 3em;
  }

  > h5 {
    color: $grey_9B9B9B;
    text-align: center;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 3em;
    font-size: 1.125em;
    font-weight: 300;

    @media (max-width: 480px) {
      margin-bottom: 2em;
    }
  }

  .repeater-row {
    text-align: center;

    @media (max-width: 480px) {
      margin-bottom: 1em;
    }

    a {
      color: #000;
      font-size: 1.25em;
      font-weight: 400;
      display: inline-block;
      position: relative;
      margin-bottom: 3em;

      @media (max-width: 480px) {
        margin-bottom: 1em;
      }

      &::after {
        display: none;
        background-image: url(../images/rubriken-arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        width: 24px;
        height: 17px;
        position: absolute;
        right: -30px;
        top: 5px;
      }

      &:hover {
        color: $kf-highlight;
        text-decoration: none;

        &::after {
          display: block;
        }
      }
    }
  }
}

.mobile-anchor {
  display: flex;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  margin-bottom: 60px !important;
  padding-left: 5px;
  padding-right: 5px;

  @media (min-width: 480px) {
    display: none !important;
  }

  @media (max-width: 360px) {
    .col {
      &:nth-of-type(1) {
        width: 60%;
        max-width: 60%;
        flex: 0 1 60%;
      }

      &:nth-of-type(2) {
        width: 40%;
        max-width: 40%;
        flex: 0 1 40%;
        padding-right: 33px !important;
      }
    }
  }

  border-bottom: 1px solid #f2f2f2;

  .article_rubriken {
    text-align: left;
  }

  .article_social {
    transform: scale(0.75) translateY(-5px);
    transform-origin: right center;

    .social-link {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }

  .article_social,
  .article_rubriken {
    padding-bottom: 0 !important;
  }

  .cbxwpbkmarkwrap > div {
    top: 0 !important;
    bottom: auto !important;
  }
}

@media screen and (max-width: 480px) {
  .mt-5, .my-5 {
    margin-top: 6em !important;
  }

  .band-navigation {
    border: none !important;
  }

  .text-col{
    border-bottom: 1px solid $kf-alternate;
    margin-top: -20px !important;
  }

  .text-col-hl{
    padding: 20px !important;
  }

}
