.popit {
  /* stylelint-disable */
  border: 5px solid $kf-highlight;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(120%);
  transition: transform 0.5s ease-in-out;
  z-index: 2147483646;

  .mobile {
    display: none !important;

    @media (max-width: 640px) {
      display: block !important;
    }
  }

  .desktop {
    display: none !important;

    @media (min-width: 640px) {
      display: block !important;
    }
  }

  .desktop-flex {
    display: none !important;

    @media (min-width: 640px) {
      display: flex !important;
    }
  }

  &.showing {
    transition: transform 1.5s ease-in-out;
    transform: translateY(0);
  }

  .popup-expand-intext {
    font-weight: bold;
    text-align: center;
    font-size: 1.125em;
  }

  .popit-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $grey_f2;
    background-image: url(../images/icon_close_x_black.svg) !important;
    background-size: 15px;
    background-repeat: no-repeat !important;
    background-position: center 25% !important;
    text-align: center;
    line-height: 1em;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: transform 0.5s ease-in-out;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }

    &.hidden {
      transform: translateY(50%);
    }
  }

  .popit-minimize {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 3em;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url(../images/icon_close_x_black.svg) !important;
    background-size: 15px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transition: transform 0.5s ease-in-out;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .popit-expand {
    position: absolute;
    top: -5px;
    right: 50%;
    text-align: center;
    line-height: 1em;
    height: 3em;
    width: 3em;
    border: 5px solid $kf-highlight;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
    transform: translateX(50%) translateY(-50%) rotate(90deg);
    transition: transform 0.5s ease-in-out;
    background-color: $grey_f2;
    background-image: url(../images/icon-arr-left-black.svg) !important;
    background-size: 10px;
    background-repeat: no-repeat !important;
    background-position: 25% !important;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .popit-leaky {
    background-color: $grey_f2;
    position: absolute;
    left: 0;
    width: 180px;
    height: 120px;
    border-right: 1px solid $grey_ddd;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: flex;


    @media (max-width: 640px) {
      width: 100px;
      height: 100px;
    }

    .leaky-count {
      text-align: center;
      margin: 0;

      span {
        font-size: 2em;
        font-weight: bold;
        line-height: 1.2em;
        display: block;
      }
    }
  }

  .popit-leaky-expander {
    background-color: $grey_f2;
    position: absolute;
    left: 180px;
    top: 0;
    width: 140px;
    height: 120px;
    border-right: 1px solid $grey_ddd;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: flex;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 640px) {
      display: none;
    }

    .popit-leaky-expander-text {
      text-align: center;
      max-width: 90%;

      &::after {
        content: '';
        display: block;
        margin: 0 auto;
        height: 40px;
        width: 40px;
        font-size: 5em;
        line-height: 0.4em;
        margin-top: 5px;
        background-image: url(../images/icon-arr-left-kf.svg) !important;
        background-size: contain;
        background-repeat: no-repeat !important;
        background-position: center;
        transform: rotate(-180deg);
      }
    }
  }

  .popit-inner {
    position: relative;
    background-color: $grey_f2;
    height: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .popit-content {
    padding: 20px;
    height: 100%;
    text-align: center;
  }

  .popit-expanded {
    background-color: $grey_f2;
    position: relative;
    z-index: 2;
    overflow: auto;
    display: none;

    @media (max-width: 640px) {
      max-height: 50vh;
    }
  }

  &.expanded {
    background-color: $grey_f2;
    height: auto !important;

    .popit-expanded {
      display: block;
    }

    .popit-expand {
      transform: translateX(50%) translateY(-50%) rotate(-90deg);
      background-position: 65% 49% !important;
    }

    .popit-close,
    .popit-leaky,
    .popit-leaky-expander,
    .popit-inner {
      display: none !important;
    }
  }

  &.hidden:not(.has-leaky):not(.non-leaky) {
    transform: translateY(125%);

    .popit-close {
      transform: translateY(200%);
    }
  }

  .button-holder {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    @media (min-width: 640px) {
      display: inline-block;
      vertical-align: super;
      margin-left: 20px;
    }
  }

  .expand-login-text {
    margin-bottom: 0;
    text-decoration: underline;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .expand-login-holder {
    max-width: 240px;
  }

  #user_login {
    margin-bottom: 5px !important;
  }

  .mobile-columns {
    margin-top: 10px;
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
    align-items: flex-start;

    .mobile-row {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px !important
    }

    .divider-with-text {
      border-top: 1px solid $grey_ddd;
      height: 1px;
      text-align: center;

      .divided-text {
        margin-top: -8px;
        margin-left: auto;
        margin-right: auto;
        background-color: $grey_f2;
        display: block;
        color: $kf-highlight;
        font-weight: bold;
        width: 80px;
      }
    }

    .mobile-abo-name {
      font-family: $font-system !important;
      text-align: center;
      font-weight: bold;
      font-size: 1.25em;
    }

    .mobile-single-button-holder {
      text-align: center;
    }

    .mobile-bullets {
      text-align: center;
      border: none !important;
      max-width: 100%;

      .mobile-bullet {
        text-align: center;
        display: inline-block;
        width: 320px;
        text-align: left;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0;
    position: relative;

    @media (min-width: 640px) {
      .column {
        &:nth-of-type(2) {
          &::before {
            background: $grey_ddd;
            bottom: 0;
            content: " ";
            right: 50%;
            position: absolute;
            width: 1px;
            top: 0;
          }
        }
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .column {
      max-width: 45%;
      width: 400px;
      padding: 20px 20px 10px 20px;
      display: flex;
      flex-direction: column;

      @media (max-width: 640px) {
        width: 280px;
        padding: 10px;
      }

      @media (max-width: 480px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }

      &.bild-column {
        img {
          max-height: 40vh;
          width: auto;
          align-self: center;
          max-width: 90%;
        }
      }

      &.form-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 20px;

        input[type="submit"] {
          margin-top: 0;
        }

        .leaky-form-title {
          font-family: $font-system;
          text-align: center;
          max-width: 100%;
          font-weight: 300;
          font-size: 1.25em;
          margin: 0 auto;
          padding-bottom: 10px;
        }

        .form-holder {
          max-width: 100%;
          width: 90%;
          margin: 0 auto;

          .expand-login-text {
            margin-top: 20px;
          }

          li.gfield {
            margin-bottom: 0 !important;
          }

          .gform_footer {
            text-align: center;
          }

          .gform_wrapper {
            max-width: 100% !important;
          }
        }

        .form-bullets {
          padding: 20px;
          margin: 0;

          .form-bullet {
            font-size: 1em;
            font-size: 0.875em;
            font-weight: 300;
            list-style: none;
            margin: 0 0 5px;
            padding: 0 0 0 1.5em;
            position: relative;

            &::before {
              content: "\2713";
              left: 0;
              position: absolute;
              top: 0;
            }
          }
        }
      }

      &.abo-column {
        border: 1px solid $grey_ddd;

        .abo-img {
          width: auto;
          height: 80px;
          margin: 10px auto 10px;
          display: block;


          @media (max-width: 640px) {
            display: none !important;
          }

          @media screen\0, screen\9 {
            width: 118px;
          }

          @supports (-ms-ime-align:auto) {
            width: 118px;
          }
        }

        .abo-name {
          font-family: $font-system !important;
          text-align: center;
          font-weight: 300;
          font-size: 1.25em;
          margin: 0 auto;
          max-width: 80%;
          padding: 0;
        }

        .abo-bullets {
          padding: 10px 20px;
          margin: 0;
          border-top: 1px solid $grey_ddd;
          border-bottom: 1px solid $grey_ddd;

          .abo-bullet {
            font-size: 0.875em;
            font-weight: 300;
            list-style: none;
            margin: 0 0 5px;
            padding: 0 0 0 1.5em;
            position: relative;


            &::before {
              content: "\2713";
              left: 0;
              position: absolute;
              top: 0;
            }
          }
        }

        .abo-preis {
          font-size: 1.25em;
          line-height: 1em;
          font-weight: 100;
          margin: 15px 0 10px;
          text-align: center;
        }

        .single-button-holder {
          text-align: center;
          margin-top: 0;
          margin-bottom: 10px;

          @media (max-width: 640px) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.has-leaky {
    .columns {
      position: relative;
    }

    .popit-leaky-expander {
      display: none;
    }

    .popit-inner {
      width: calc(100% - 180px);
      height: 120px;
      overflow: hidden;
      left: 180px;

      @media (max-width: 640px) {
        width: calc(100% - 110px);
        left: 110px;
        height: 100px;
        overflow: hidden;
      }

      .popit-content {
        opacity: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
        transition: width 0.5s ease-in-out, opacity 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.collapsed {
          width: 180px;
          opacity: 0;
        }
      }
    }

    .popit-leaky {
      @media (max-width: 640px) {
        width: 110px;
      }
    }

    &.hidden {
      width: 330px;
      overflow: hidden;
      transform: translateY(0%);
      transition: transform 0s ease-in-out;

      .popit-leaky-expander {
        display: flex;
        @media (max-width: 640px) {
          left: 110px;
        }
      }

      @media (max-width: 640px) {
        width: 260px;
      }

      .popit-close {
        display: none;
      }
    }
  }

  &.non-leaky {
    .columns {
      position: relative;
    }

    .popit-leaky,
    .popit-leaky-expander {
      display: none;
    }

    .popit-inner {
      width: 100%;
      height: 120px;
      overflow: hidden;
      left: 0;

      @media (max-width: 640px) {
        height: 100px;
        overflow: hidden;
      }

      .popit-content {
        opacity: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
        transition: width 0.5s ease-in-out, opacity 0.2s ease-in-out;
        display: flex;
        text-align: center;

        &.collapsed {
          width: 180px;
          opacity: 0;
        }
      }
    }

    &.hidden {
      transform: translateY(125%);

      .popit-close {
        transform: translateY(200%);
      }
    }
  }

  h3 {
    font-family: $font-headline !important;
    font-size: 2em !important;
    line-height: 1.1em !important;
    margin-bottom: 10px;
    text-align: center;
    padding-bottom: 0 !important;
    display: inline-block;

    @media (max-width: 640px) {
      font-size: 1.5em !important;
    }

    &.second-headline {
      font-family: $font-system !important;
      font-size: 1.25em !important;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .pp-subline {
    text-align: center;
    max-width: 80%;
    margin: 0 auto 20px;
    font-size: 1.25em;
  }
  /* stylelint-enable */
}
